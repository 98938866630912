import Auth from '@aws-amplify/auth'
import {createContext, createResource, Match, ParentComponent, Switch, useContext} from 'solid-js'
import {useIamService} from '../AppServiceProvider'
import {Permissions} from './Permissions'

const PermissionsContext = createContext<Permissions>()

async function load (): Promise<Permissions> {
    try {
        const token = (await Auth.currentSession()).getIdToken().decodePayload()
        return new Permissions(token)
    }
    catch (e) {
        //expected if the user is not signed in
    }
}

export const PermissionsProvider: ParentComponent = (props) => {
    const iamService = useIamService()

    const [permissions] = createResource(() => iamService.isSignedIn(), load)

    return <>
        <Switch>
            <Match when={!iamService.isSignedIn()}>
                {props.children}
            </Match>
            <Match when={iamService.isSignedIn() && permissions()}>
                <PermissionsContext.Provider value={ permissions() }>
                    {props.children}
                </PermissionsContext.Provider>
            </Match>
        </Switch>
    </>
}

export const getPermissionsContext = () => useContext(PermissionsContext)

export function canManageQuotes() {
    return getPermissionsContext()?.canManageQuotes()
}
