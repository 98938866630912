.v7h4Za_IamModal {
  flex-direction: column;
  min-width: 25em;
  padding: 1.5em 3em;
}

.v7h4Za_IamModal a {
  text-align: right;
  font-size: .8em;
}

.v7h4Za_IamModal ul {
  color: var(--peachy-purple);
  margin-left: 1em;
  font-size: .7em;
  list-style-type: disc;
}

.v7h4Za_IamModal mark {
  color: var(--peachy-pink);
  background-color: #fff;
  font-size: .8em;
  font-weight: 600;
}

.v7h4Za_IamModal mark:before {
  content: "!";
  background-color: var(--peachy-pink);
  color: #fff;
  text-align: center;
  border-radius: 100%;
  width: 1em;
  margin-right: .5em;
  display: inline-block;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7OztBQU1BOzs7OztBQUtBOzs7Ozs7O0FBT0E7Ozs7Ozs7QUFPQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvSWFtL0lhbU1vZGFsLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLklhbU1vZGFsIHtcbiAgICBtaW4td2lkdGg6IDI1ZW07XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBwYWRkaW5nOiAxLjVlbSAzZW07XG59XG5cbi5JYW1Nb2RhbCBhIHtcbiAgICB0ZXh0LWFsaWduOiByaWdodDtcbiAgICBmb250LXNpemU6IDAuOGVtO1xufVxuXG4uSWFtTW9kYWwgdWwge1xuICAgIGNvbG9yOiB2YXIoLS1wZWFjaHktcHVycGxlKTtcbiAgICBtYXJnaW4tbGVmdDogMWVtO1xuICAgIGxpc3Qtc3R5bGUtdHlwZTogZGlzYztcbiAgICBmb250LXNpemU6IDAuN2VtO1xufVxuXG4uSWFtTW9kYWwgbWFyayB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogd2hpdGU7XG4gICAgY29sb3I6IHZhcigtLXBlYWNoeS1waW5rKTtcbiAgICBmb250LXNpemU6IDAuOGVtO1xuICAgIGZvbnQtd2VpZ2h0OiA2MDA7XG59XG5cbi5JYW1Nb2RhbCBtYXJrOjpiZWZvcmUge1xuICAgIGNvbnRlbnQ6ICchJztcbiAgICB3aWR0aDogMWVtO1xuICAgIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1wZWFjaHktcGluayk7XG4gICAgY29sb3I6IHdoaXRlO1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICBtYXJnaW4tcmlnaHQ6IDAuNWVtO1xuICAgIGJvcmRlci1yYWRpdXM6IDEwMCU7XG59XG4iXSwibmFtZXMiOltdfQ== */