import {Address, Company, LifeType, MrAccount, MrLife, MrPolicy, PaymentDetails} from '@peachy/core-domain-pure'
import {Draft} from '@peachy/utility-kit-pure'
import {createDraftLife, createDraftPolicy} from '../create-subscription-requests'
import {OtherPropertiesStore, ViewMode} from './OtherPropertiesStore'
import {PlanLifeAssignmentStore} from './PlanLifeAssignmentStore'
import {SubscriptionQuoteStore} from './SubscriptionQuoteStore'
import {batch, mergeProps} from 'solid-js'
import {getQuoteStore} from '../session/stores'
import {SubscriptionQuoteRepository} from '../../../service/subscription/SubscriptionQuoteRepository'
import {PlanConfig} from '@peachy/product-client'
import {startOfToday} from 'date-fns'

export const BENCH_ID = 'bench'
const MIN_EMPLOYEES = 2
const MAX_EMPLOYEES = 50

export class AccountSubscriptionStore {
    private subscriptionQuoteStore: SubscriptionQuoteStore
    private planLifeAssignmentStore: PlanLifeAssignmentStore
    private otherPropertiesStore: OtherPropertiesStore

    public onPortalCancel: () => void
    public onPortalEdit: () => void

    constructor (private readonly subscriptionQuoteRepository: SubscriptionQuoteRepository, private readonly planConfig: PlanConfig) {
        this.subscriptionQuoteStore = new SubscriptionQuoteStore(subscriptionQuoteRepository, this.planConfig)
        this.planLifeAssignmentStore = new PlanLifeAssignmentStore(this.getPlans(), this.getLives())
        this.otherPropertiesStore = new OtherPropertiesStore(['Unassigned'].concat(this.getPlanNames()))
    }

    public reset () {
        getQuoteStore()?.delete()
        this.subscriptionQuoteRepository.delete()
    }

    public assignLifeToPlan (params: AssignLifeToPlanParams) {
        const defaults = {
            toPlanId: BENCH_ID,
            fromPlanId: BENCH_ID,
            orderIndex: 0
        }

        const paramsWithDefaults = mergeProps(defaults, params)

        batch(() => {
            if (params.type === 'PRIMARY') {
                this.updateLifeOrder(paramsWithDefaults.fromPlanId, paramsWithDefaults.toPlanId, paramsWithDefaults.lifeId, paramsWithDefaults.orderIndex)
            }

            this.updateLife(
                paramsWithDefaults.policyIndex ?? this.getPolicyIndexForLife(paramsWithDefaults.lifeId),
                paramsWithDefaults.lifeId,
                { planId: paramsWithDefaults.toPlanId === BENCH_ID ? undefined : paramsWithDefaults.toPlanId }
            )
        })
    }

    public assignPolicyToPlan (params: AssignPolicyToPlanParams) {
        batch(() => {
            Object.values(params.policy.lives).forEach(life => {
                this.assignLifeToPlan({
                    lifeId: life.id,
                    fromPlanId: params.fromPlanId,
                    policyIndex: params.policyIndex,
                    toPlanId: params.toPlanId,
                    orderIndex: params.orderIndex,
                    type: life.type
                })
            })
        })
    }

    public getSubscriptionRequest() {
        return this.subscriptionQuoteStore.getSubscriptionRequest()
    }

    public canAddPolicy() {
        return this.subscriptionQuoteStore.getPolicies().length < MAX_EMPLOYEES
    }

    public canRemovePolicy() {
        return this.subscriptionQuoteStore.getPolicies().length > MIN_EMPLOYEES
    }

    public getUser() {
        return this.subscriptionQuoteStore.getUser()
    }

    public getAccount(): Draft<MrAccount> {
        return this.subscriptionQuoteStore.getAccount()
    }

    public getCompany() {
        return this.subscriptionQuoteStore.getCompany()
    }

    public getSubscriptionStartDate() {
        return this.subscriptionQuoteStore.getSubscriptionStartDate()
    }

    public getPolicy(id : string) {
        return this.subscriptionQuoteStore.getPolicies().find(p => p.id === id)
    }

    public getPolicies() {
        return this.subscriptionQuoteStore.getPolicies()
    }

    public getPolicyForLife(lifeId: string) {
        return this.subscriptionQuoteStore.getPolicyForLife(lifeId)
    }

    public getPolicyIndexForLife(lifeId: string) {
        return this.subscriptionQuoteStore.getPolicyIndexForLife(lifeId)
    }

    public getPoliciesAssignedToPlans() {
        return this.getPolicies().filter(policy => Boolean(Object.values(policy.lives)[0].planId))
    }

    public hasValidSmeLifeCount() {
        return this.getPoliciesAssignedToPlans().length > 1
    }

    public hasSubscriptionStartDateExpired() {
        return this.getSubscriptionStartDate() < startOfToday().getTime()
    }

    public canPurchase() {
        return this.hasValidSmeLifeCount() && this.isValidPolicies() && this.isEmailsUnique()
    }

    public getLives() {
        return this.subscriptionQuoteStore.getLives()
    }

    public getLivesForPlan(planId: string) {
        return this.subscriptionQuoteStore.getLivesForPlan(planId)
    }

    public hasSomeLifeForPlan(planId: string) {
        return this.subscriptionQuoteStore.hasSomeLifeForPlan(planId)
    }

    public getLivesForPlanOrdered(planId?: string) {
        return this.planLifeAssignmentStore.orderLives(planId ?? BENCH_ID, this.subscriptionQuoteStore.getLivesForPlan(planId))
    }

    public getPlans() {
        return this.subscriptionQuoteStore.getPlans()
    }

    public getPlansInSelector() {
        return this.otherPropertiesStore.getPlansInSelector()
    }

    public getPlanById(id: string) {
        return this.subscriptionQuoteStore.getPlans().find(p => id === p.id)
    }

    public getPlanIds() {
        return this.subscriptionQuoteStore.getPlans().map(p => p.id)
    }

    public getPlanNames() {
        return this.subscriptionQuoteStore.getPlans().map(p => p.name)
    }

    public getOldestDateOfBirthForLives(youngestAge: number, filter: (life: Draft<MrLife>) => boolean) {
        return this.subscriptionQuoteStore.getOldestDateOfBirthForLives(youngestAge, filter)
    }

    public getBenefitLimit(planId: string, benefitId: string) {
        return this.getBenefit(planId, benefitId).limit
    }

    public getBenefit(planId: string, benefitId: string) {
        return this.subscriptionQuoteStore.getBenefit(planId, benefitId)
    }

    public getExcess(planId: string) {
        return this.subscriptionQuoteStore.getExcess(planId)
    }

    public updateUserName(firstName: string, lastName: string) {
        this.subscriptionQuoteStore.updateUserName(firstName, lastName)
    }

    public updateCompany(company: Company) {
        this.subscriptionQuoteStore.updateCompany(company)
    }

    public updateEmail(email: string) {
        this.subscriptionQuoteStore.updateEmail(email)
    }

    public updateMarketingAccepted(marketingAccepted: boolean) {
        this.subscriptionQuoteStore.updateMarketingAccepted(marketingAccepted)
    }

    public updatePoliciesAccepted(policiesAccepted: boolean) {
        this.subscriptionQuoteStore.updatePoliciesAccepted(policiesAccepted)
    }

    public updateUserCognitoId(cognitoUserId: string) {
        this.subscriptionQuoteStore.updateUserCognitoId(cognitoUserId)
    }

    public addPolicy(planId = BENCH_ID) {
        const newPolicy = createDraftPolicy(this.getSubscriptionStartDate(), planId === BENCH_ID ? undefined : planId)
        this.subscriptionQuoteStore.addPolicy(newPolicy)
        this.planLifeAssignmentStore.addLifeOrder(planId, getPrimaryLife(newPolicy).id)
        // getting new policy from store so it is consistently a Solid proxy object, not sure if this is important.
        const policies = this.getPolicies()
        return policies[policies.length - 1]
    }

    public removeLife(policyIndex: number, lifeId: string) {
        this.subscriptionQuoteStore.removeLife(policyIndex, lifeId)
    }

    public removePolicy(removeIndex: number) {
        this.subscriptionQuoteStore.removePolicy(removeIndex)
    }

    public isValidPolicies() {
        return this.subscriptionQuoteStore.isValidPolicies()
    }

    public isEmailsUnique() {
        const emails = this.getLives().filter(l => l.email).map(l => l.email.toLowerCase())
        return emails.length === new Set(emails).size
    }

    public isEmailDuplicate(email: string): boolean {
        const emailToCompare = email.toLowerCase()
        return this.getLives().filter(l => l.email && (l.email.toLowerCase() === emailToCompare)).length > 1
    }

    public updateStartDate(startDate: number) {
        return this.subscriptionQuoteStore.updateStartDate(startDate)
    }

    public addNonPrimaryLife(policyIndex: number, planId: string, type: LifeType, address : Draft<Address>) {
        const life = createDraftLife(planId, type, address)
        this.subscriptionQuoteStore.updateLife(policyIndex, life.id, life)
    }

    public updateLife(policyIndex: number, lifeId: string, fields: Draft<MrLife>) {
        this.subscriptionQuoteStore.updateLife(policyIndex, lifeId, fields)
    }

    public updateLivesForPolicy(policyIndex: number, policy: Draft<MrPolicy>, fields: Draft<MrLife>) {
        batch(() => {
            Object.values(policy.lives).forEach(life => this.updateLife(policyIndex, life.id, fields))
        })
    }


    public updateLifeOrder(fromPlanId: string, toPlanId: string, lifeId: string, orderIndex: number) {
        this.planLifeAssignmentStore.updateLifeOrder(fromPlanId, toPlanId, lifeId, orderIndex)
    }

    public addBenefit(planId: string, benefitId: string) {
        this.subscriptionQuoteStore.addBenefit(planId, benefitId)
    }

    public removeBenefit (planId: string, benefitId: string) {
        this.subscriptionQuoteStore.removeBenefit(planId, benefitId)
    }

    public updateBenefitLimit(planId: string, benefitId: string, limit: number) {
        this.subscriptionQuoteStore.updateBenefitLimit(planId, benefitId, limit)
    }

    public updatePlanExcess(planId: string, limit: number) {
        this.subscriptionQuoteStore.updatePlanExcess(planId, limit)
    }

    public updatePayment(paymentDetails: Draft<PaymentDetails>) {
        this.subscriptionQuoteStore.updatePayment(paymentDetails)
    }

    public updateBillingAddress(hasSeparateAddress: boolean, address: Draft<Address>) {
        this.subscriptionQuoteStore.updateBillingAddress(hasSeparateAddress, address)
    }

    public getPaymentDetails() {
        return this.subscriptionQuoteStore.getPaymentDetails()
    }

    public startEditing() {
        this.otherPropertiesStore.setIsEditing(true)
    }

    public stopEditing() {
        this.otherPropertiesStore.setIsEditing(false)
    }

    public isEditing() {
        return this.otherPropertiesStore.getIsEditing()
    }

    public setFullValidation(value: boolean) {
        this.otherPropertiesStore.setIsFullValidation(value)
    }

    public isFullValidation() {
        return this.otherPropertiesStore.getIsFullValidation()
    }

    public setPlanEditable(value: boolean) {
        this.otherPropertiesStore.setIsPlanEditable(value)
    }

    public isPlanEditable() {
        return this.otherPropertiesStore.getIsPlanEditable()
    }

    public setViewMode(viewMode: ViewMode) {
        this.otherPropertiesStore.setViewMode(viewMode)
    }

    public getViewMode() {
        return this.otherPropertiesStore.getViewMode()
    }

    public getMinimumPoliciesCount() {
        return MIN_EMPLOYEES
    }

    public canEditLife(lifeId: string) {
        return !this.otherPropertiesStore.getLockedLives()?.find((life: MrLife) => life.id === lifeId)
    }

    public canBulkAdd() {
        return this.getLockLives().length === 0
    }

    public getLockLives() {
        return this.otherPropertiesStore.getLockedLives()
    }

    public setLockedLives(lives: MrLife[]) {
        this.otherPropertiesStore.setLockedLives(lives)
    }

    public getDeletedLives() {
        return this.getPlans().flatMap(p => this.getDeletedLivesForPlan(p.id))
    }

    public getDeletedLivesForPlan (planId: string) {
        const activeLives = this.getLivesForPlan(planId)
        const existingLives = this.otherPropertiesStore.getLockedLivesForPlan(planId)
        return existingLives?.filter((lockedLife: MrLife) => !activeLives.find((life: MrLife) => life.id === lockedLife.id))
    }

    public addPlaceholderPolicies() {
        if (this.getPolicies().length < this.getMinimumPoliciesCount()) {
            const placeHolderPolicies = this.getMinimumPoliciesCount() - this.getPolicies().length

            for (let i = 0; i < placeHolderPolicies; i++) {
                this.addPolicy()
            }
        }
    }

    public removePlaceholderPolicies() {
        if (this.getPolicies().length === this.getMinimumPoliciesCount()) {
            // Traverse in reverse due to the array indexes being reduced as we remove items
            for (let i = this.getPolicies().length - 1; i >= 0; i--) {
                if (this.isPolicyPlaceholder(i)) {
                    this.removePolicy(i)
                }
            }
        }
    }

    public getIndexForNewPolicy(): number {
        const policies = this.getPolicies()
        for (let i = policies.length - 1; i >= 0; i--) {
            if (!this.isPolicyPlaceholder(i)) {
                return i + 1
            }
        }
        return 0
    }

    public isPolicyPlaceholder(index: number): boolean {
        const life = getPrimaryLife(this.getPolicies()[index])
        return !life.planId && !life.firstname && !life.lastname && !life.dateOfBirth && !life.address && !life.email
    }
}

// TODO where to put this. Used in many places
export function getPrimaryLife(policy: Draft<MrPolicy>): Draft<MrLife> {
    return getLife(policy, 'PRIMARY')
}

export function getSecondaryLife(policy: Draft<MrPolicy>): Draft<MrLife> {
    return getLife(policy, 'SECONDARY')
}

export function getDependants(policy: Draft<MrPolicy>): Draft<MrLife>[] {
    return Object.values(policy.lives).filter(l => l.type === 'DEPENDANT')
}

function getLife(policy: Draft<MrPolicy>, type: LifeType) {
    return Object.values(policy.lives).find(l => l.type === type)
}

type AssignLifeToPlanParams = {
    lifeId: string,
    fromPlanId: string,
    policyIndex?: number,
    toPlanId?: string,
    orderIndex?: number
    type?: LifeType
}

type AssignPolicyToPlanParams = {
    policy: Draft<MrPolicy>,
    fromPlanId: string,
    policyIndex?: number,
    toPlanId?: string,
    orderIndex?: number
}
