._3lhh7G_Card {
  text-align: center;
  border-radius: .5em;
  width: 20em;
  padding: 1em 0;
}

._3lhh7G_Default {
  background-color: var(--neutral-dark);
  color: var(--button-primary-font-color);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQ0E7Ozs7Ozs7QUFPQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvRm9ybWF0dGVkUHJpY2UvQ2FyZC9DYXJkLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiXG4uQ2FyZCB7XG4gICAgYm9yZGVyLXJhZGl1czogMC41ZW07XG4gICAgcGFkZGluZzogMWVtIDA7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICAgIHdpZHRoOiAyMGVtO1xufVxuXG4uRGVmYXVsdCB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tbmV1dHJhbC1kYXJrKTtcbiAgICBjb2xvcjogdmFyKC0tYnV0dG9uLXByaW1hcnktZm9udC1jb2xvcik7XG59Il0sIm5hbWVzIjpbXX0= */