._9k2Rsa_PremiumDetails {
  flex-direction: column;
  align-items: center;
  row-gap: 2em;
  margin-top: 1em;
  display: flex;
}

._9k2Rsa_Cost {
  text-align: center;
  border-radius: .5em;
  width: 20em;
  padding: 1em 0;
}

._9k2Rsa_Month {
  background-color: var(--button-primary-bg-color);
  color: var(--button-primary-font-color);
}

._9k2Rsa_Year {
  background-color: var(--button-dark-bg-color);
  color: var(--button-dark-font-color);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7O0FBUUE7Ozs7Ozs7QUFPQTs7Ozs7QUFLQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL3BhZ2VzL3F1b3RlL1N1bW1hcnkvUHJlbWl1bURldGFpbHMubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuUHJlbWl1bURldGFpbHMge1xuICAgIG1hcmdpbi10b3A6IDFlbTtcbiAgICByb3ctZ2FwOiAyZW07XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG59XG5cbi5Db3N0IHtcbiAgICBib3JkZXItcmFkaXVzOiAwLjVlbTtcbiAgICBwYWRkaW5nOiAxZW0gMDtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgd2lkdGg6IDIwZW07XG59XG5cbi5Nb250aCB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tYnV0dG9uLXByaW1hcnktYmctY29sb3IpO1xuICAgIGNvbG9yOiB2YXIoLS1idXR0b24tcHJpbWFyeS1mb250LWNvbG9yKTtcbn1cblxuLlllYXIge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWJ1dHRvbi1kYXJrLWJnLWNvbG9yKTtcbiAgICBjb2xvcjogdmFyKC0tYnV0dG9uLWRhcmstZm9udC1jb2xvcik7XG59Il0sIm5hbWVzIjpbXX0= */