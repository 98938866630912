.tDBt8G_SubHeader {
  grid-template-columns: auto 16px;
  display: grid;
}

.tDBt8G_SubHeader * {
  font-size: inherit;
}

.tDBt8G_Edit {
  text-align: right;
}

.tDBt8G_Edit > button {
  padding: unset;
  border: unset;
  background-color: unset;
  color: initial;
  font-weight: normal;
  font-size: var(--font-size-xsmall);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQ0E7Ozs7O0FBS0E7Ozs7QUFJQTs7OztBQUlBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvcGFnZXMvZGFzaGJvYXJkL3BhbmVscy9wdXJlL1N1YkhlYWRlci5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIlxuLlN1YkhlYWRlciB7XG4gICAgZGlzcGxheTogZ3JpZDtcbiAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IGF1dG8gMTZweDtcbn1cblxuLlN1YkhlYWRlciAqIHtcbiAgICBmb250LXNpemU6IGluaGVyaXQ7XG59XG5cbi5FZGl0IHtcbiAgICB0ZXh0LWFsaWduOiByaWdodDtcbn1cblxuLkVkaXQgPiBidXR0b24ge1xuICAgIHBhZGRpbmc6IHVuc2V0O1xuICAgIGJvcmRlcjogdW5zZXQ7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdW5zZXQ7XG4gICAgY29sb3I6IGluaXRpYWw7XG4gICAgZm9udC13ZWlnaHQ6IG5vcm1hbDtcbiAgICBmb250LXNpemU6IHZhcigtLWZvbnQtc2l6ZS14c21hbGwpO1xufSJdLCJuYW1lcyI6W119 */