.RJ5I3q_Modal {
  cursor: wait;
  pointer-events: all;
  z-index: 100;
  background: #fffb;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.RJ5I3q_Spinner {
  flex-direction: row;
  justify-content: center;
  gap: 3em;
  display: flex;
}

.RJ5I3q_Dot {
  background-color: var(--peachy-pink);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: .4s ease-out infinite alternate RJ5I3q_pulse;
}

@media screen and (width <= 900px) {
  .RJ5I3q_Dot {
    width: 50px;
    height: 50px;
  }
}

.RJ5I3q_Dot:nth-child(2) {
  animation-delay: .125s;
}

.RJ5I3q_Dot:nth-child(3) {
  animation-delay: .25s;
}

@keyframes RJ5I3q_pulse {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

clickable:hover {
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7OztBQVlBOzs7Ozs7O0FBT0E7Ozs7Ozs7O0FBU0E7RUFDSTs7Ozs7O0FBTUo7Ozs7QUFJQTs7OztBQU1BOzs7Ozs7Ozs7Ozs7QUFZQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vY2xpZW50LWtpdC9zcmMvY29tcG9uZW50cy9TcGlubmVyL1NwaW5uZXIubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuTW9kYWwge1xuICAgIGN1cnNvcjogd2FpdDtcbiAgICBwb2ludGVyLWV2ZW50czogYWxsO1xuICAgIHotaW5kZXg6IDEwMDtcbiAgICBiYWNrZ3JvdW5kOiAjRkZGRkZGQkI7XG4gICAgd2lkdGg6IDEwMHZ3O1xuICAgIGhlaWdodDogMTAwdmg7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xufVxuXG4uU3Bpbm5lciB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICAgIGdhcDogM2VtO1xufVxuXG4uRG90IHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1wZWFjaHktcGluayk7XG4gICAgYm9yZGVyLXJhZGl1czogNTAlO1xuICAgIGFuaW1hdGlvbjogcHVsc2UgNDAwbXMgZWFzZS1vdXQgaW5maW5pdGUgYWx0ZXJuYXRlO1xuICAgIGhlaWdodDogODBweDtcbiAgICB3aWR0aDogODBweDtcbn1cblxuLyogaWYgd2UgaGF2ZSBhIHNtYWxsZXIgc2NyZWVuIHNpemUsIG1ha2UgdGhlIGRvdHMgc21hbGxlciAqL1xuQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogOTAwcHgpIHtcbiAgICAuRG90IHtcbiAgICAgICAgaGVpZ2h0OiA1MHB4O1xuICAgICAgICB3aWR0aDogNTBweDtcbiAgICB9XG59XG5cbi5Eb3Q6bnRoLWNoaWxkKDIpIHtcbiAgICBhbmltYXRpb24tZGVsYXk6IDEyNW1zO1xufVxuXG4uRG90Om50aC1jaGlsZCgzKSB7XG4gICAgYW5pbWF0aW9uLWRlbGF5OiAyNTBtcztcbn1cblxuXG5cbkBrZXlmcmFtZXMgcHVsc2Uge1xuICAgIDAlIHtcbiAgICAgICAgdHJhbnNmb3JtOiBzY2FsZSgwKTtcbiAgICAgICAgb3BhY2l0eTogMDtcbiAgICB9XG4gICAgMTAwJSB7XG4gICAgICAgIHRyYW5zZm9ybTogc2NhbGUoMSk7XG4gICAgICAgIG9wYWNpdHk6IDE7XG4gICAgfVxufVxuXG4vKiB0aGlzIGlzIGNvcGllZCBmcm9tIHBlYWNoeS1saWJyYXJ5Lm1vZHVsZS5jc3MgKi9cbmNsaWNrYWJsZTpob3ZlciB7XG4gICAgY3Vyc29yOiBwb2ludGVyO1xufVxuIl0sIm5hbWVzIjpbXX0= */