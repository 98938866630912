.zc3sMG_priceArticle {
  cursor: pointer;
  flex-direction: column;
  display: flex;
}

.zc3sMG_priceExplained {
  font-size: var(--font-size-xsmall);
}

.zc3sMG_priceBreakdownModal {
  max-width: 51em;
  padding: 2em 7em;
}

@media screen and (width <= 600px) {
  .zc3sMG_priceBreakdownModal {
    padding: 5em 1em;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7OztBQU1BOzs7O0FBSUE7Ozs7O0FBS0E7RUFDSSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvUXVvdGUvUXVvdGVGb290ZXIvUHJpY2VCcmVha2Rvd25Nb2RhbC5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5wcmljZUFydGljbGUge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cbi5wcmljZUV4cGxhaW5lZCB7XG4gICAgZm9udC1zaXplOiB2YXIoLS1mb250LXNpemUteHNtYWxsKTtcbn1cblxuLnByaWNlQnJlYWtkb3duTW9kYWwge1xuICAgIG1heC13aWR0aDogNTFlbTtcbiAgICBwYWRkaW5nOiAyZW0gN2VtIDJlbSA3ZW07XG59XG5cbkBtZWRpYSBzY3JlZW4gYW5kIChtYXgtd2lkdGg6IDYwMHB4KSB7XG4gICAgLnByaWNlQnJlYWtkb3duTW9kYWwge1xuICAgICAgICBwYWRkaW5nOiA1ZW0gMWVtXG4gICAgfVxufSJdLCJuYW1lcyI6W119 */