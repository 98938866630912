._fH7lq_listContainer {
  align-self: normal;
  margin: 0 150px;
}

._fH7lq_listContainer > section {
  flex-direction: column;
  row-gap: 2em;
  margin-top: 4em;
  display: flex;
}

._fH7lq_table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

._fH7lq_table tbody:before {
  content: "@";
  visibility: hidden;
  line-height: 20px;
}

._fH7lq_table :where(th) {
  color: var(--neutral-dark);
}

._fH7lq_table :where(th, td) {
  font-size: medium;
  font-weight: normal;
  font-size: var(--font-size-root-xxsmall);
  background-color: #fff;
  padding: 2em;
}

._fH7lq_table :where(th, td):first-child {
  border-radius: 10px 0 0 10px;
}

._fH7lq_table :where(th, td):last-child {
  border-radius: 0 10px 10px 0;
}

._fH7lq_dateIssued {
  width: 60%;
}

._fH7lq_link {
  color: initial;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0E7Ozs7Ozs7QUFPQTs7Ozs7QUFNQTs7Ozs7O0FBTUE7Ozs7QUFJQTs7Ozs7Ozs7QUFRQTs7OztBQUlBOzs7O0FBSUE7Ozs7QUFJQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL3BhZ2VzL2Rhc2hib2FyZC9EYXNoYm9hcmRUYWJsZS5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5saXN0Q29udGFpbmVyIHtcbiAgICBtYXJnaW46IDAgMTUwcHg7XG4gICAgYWxpZ24tc2VsZjogbm9ybWFsO1xufVxuXG4ubGlzdENvbnRhaW5lciA+IHNlY3Rpb24ge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICByb3ctZ2FwOiAyZW07XG4gICAgbWFyZ2luLXRvcDogNGVtO1xufVxuXG4udGFibGUge1xuICAgIGJvcmRlci1jb2xsYXBzZTogc2VwYXJhdGU7XG4gICAgYm9yZGVyLXNwYWNpbmc6IDAgMTBweDtcbn1cblxuLyogU3BhY2UgYmV0d2VlbiB0aGVhZCBhbmQgdGJvZHkuIEJvcmRlciBzcGFjaW5nICsgbGluZSBoZWlnaHQqL1xuLnRhYmxlIHRib2R5OmJlZm9yZSB7XG4gICAgY29udGVudDpcIkBcIjtcbiAgICBsaW5lLWhlaWdodDogMjBweDtcbiAgICB2aXNpYmlsaXR5OiBoaWRkZW47XG59XG5cbi50YWJsZSA6d2hlcmUodGgpIHtcbiAgICBjb2xvcjogdmFyKC0tbmV1dHJhbC1kYXJrKTtcbn1cblxuLnRhYmxlIDp3aGVyZSh0aCwgdGQpIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICBmb250LXNpemU6IG1lZGl1bTtcbiAgICBmb250LXdlaWdodDogbm9ybWFsO1xuICAgIHBhZGRpbmc6IDJlbTtcbiAgICBmb250LXNpemU6IHZhcigtLWZvbnQtc2l6ZS1yb290LXh4c21hbGwpO1xufVxuXG4udGFibGUgOndoZXJlKHRoLHRkKTpmaXJzdC1jaGlsZCB7XG4gICAgYm9yZGVyLXJhZGl1czogMTBweCAwIDAgMTBweDtcbn1cblxuLnRhYmxlIDp3aGVyZSh0aCx0ZCk6bGFzdC1jaGlsZCB7XG4gICAgYm9yZGVyLXJhZGl1czogMCAxMHB4IDEwcHggMDtcbn1cblxuLmRhdGVJc3N1ZWQge1xuICAgIHdpZHRoOiA2MCU7XG59XG5cbi5saW5rIHtcbiAgICBjb2xvcjogaW5pdGlhbDtcbn1cbiJdLCJuYW1lcyI6W119 */