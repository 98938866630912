.ieUH6q_errors {
  color: var(--peachy-purple);
  font-size: var(--font-size-small);
}

.ieUH6q_errorItem:before {
  content: "!";
  border: 2px solid var(--peachy-purple);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  margin-right: 1em;
  font-weight: bold;
  display: inline-flex;
}

.ieUH6q_errorItem {
  display: none;
}

.ieUH6q_errorItem:first-child {
  display: block;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0E7Ozs7Ozs7Ozs7Ozs7QUFhQTs7OztBQUlBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvZ2xvYmFsL2Vycm9ycy9WYWxpZGF0aW9uRXJyb3JzLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLmVycm9ycyB7XG4gICAgY29sb3I6IHZhcigtLXBlYWNoeS1wdXJwbGUpO1xuICAgIGZvbnQtc2l6ZTogdmFyKC0tZm9udC1zaXplLXNtYWxsKTtcbn1cblxuLmVycm9ySXRlbTo6YmVmb3JlIHtcbiAgICBjb250ZW50OiBcIiFcIjtcbiAgICBib3JkZXI6IDJweCBzb2xpZCB2YXIoLS1wZWFjaHktcHVycGxlKTtcbiAgICBib3JkZXItcmFkaXVzOiAxMDAlO1xuICAgIHdpZHRoOiAxLjVlbTtcbiAgICBoZWlnaHQ6IDEuNWVtO1xuICAgIGRpc3BsYXk6IGlubGluZS1mbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gICAgbWFyZ2luLXJpZ2h0OiAxZW07XG59XG5cbi5lcnJvckl0ZW0ge1xuICAgIGRpc3BsYXk6IG5vbmU7XG59XG5cbi5lcnJvckl0ZW06Zmlyc3QtY2hpbGQge1xuICAgIGRpc3BsYXk6IGJsb2NrO1xufSJdLCJuYW1lcyI6W119 */