.MZTLPa_DatePickerMonthTable {
  table-layout: fixed;
  text-align: center;
}

.MZTLPa_DatePickerMonthTable th {
  text-align: center;
  padding: .5em;
}

.MZTLPa_DatePickerMonthTable td {
  text-align: center;
  vertical-align: middle;
  border: 1px solid #d3d3d3;
}

.MZTLPa_allDays {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  line-height: 2em;
  display: inline-flex;
}

.MZTLPa_outMonth {
  opacity: .5;
}

.MZTLPa_disabled {
  opacity: .25;
  cursor: not-allowed;
  text-decoration: line-through;
}

.MZTLPa_selected {
  font-weight: bold;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0E7Ozs7O0FBS0E7Ozs7OztBQU9BOzs7Ozs7OztBQVFBOzs7O0FBUUE7Ozs7OztBQU1BIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9jbGllbnQta2l0L3NyYy9jb21wb25lbnRzL0RhdGVQaWNrZXIvTW9udGhUYWJsZS9Nb250aFRhYmxlLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLkRhdGVQaWNrZXJNb250aFRhYmxlIHtcbiAgICB0YWJsZS1sYXlvdXQ6IGZpeGVkO1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbn1cblxuLkRhdGVQaWNrZXJNb250aFRhYmxlIHRoIHtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgcGFkZGluZzogMC41ZW07XG59XG5cbi5EYXRlUGlja2VyTW9udGhUYWJsZSB0ZCB7XG4gICAgYm9yZGVyOiAxcHggc29saWQgbGlnaHRncmF5O1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICB2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlO1xufVxuXG5cbi5hbGxEYXlzIHtcbiAgICBkaXNwbGF5OiBpbmxpbmUtZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICAgIGxpbmUtaGVpZ2h0OiAyZW07XG4gICAgY3Vyc29yOiBwb2ludGVyO1xufVxuXG4ub3V0TW9udGgge1xuICAgIG9wYWNpdHk6IDUwJTtcbn1cblxuLmluTW9udGgge1xuXG59XG5cbi5kaXNhYmxlZCB7XG4gICAgdGV4dC1kZWNvcmF0aW9uOiBsaW5lLXRocm91Z2g7XG4gICAgb3BhY2l0eTogMjUlO1xuICAgIGN1cnNvcjogbm90LWFsbG93ZWQ7XG59XG5cbi5zZWxlY3RlZCB7XG4gICAgZm9udC13ZWlnaHQ6IGJvbGQ7XG59XG5cbi50b2RheSB7XG5cbn1cblxuLndlZWtlbmQge1xuXG59XG5cbi53ZWVrZGF5IHtcblxufVxuXG4iXSwibmFtZXMiOltdfQ== */