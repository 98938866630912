import {createToggleSignal} from '@peachy/client-kit'
import {Show} from 'solid-js'
import {ManageMembersModal} from '../../../../components/ManageMembers/modal/ManageMembersModal'
import {PriceBreakdownModal} from '../../../../components/Quote/QuoteFooter/PriceBreakdownModal'
import {Button} from '../../../../global/forms/Button'
import {useStore} from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import styles from './QuoteFooter.module.css'

// TODO standardise footer at some point
export const QuoteFooter = () => {
    const [isEmployeeModalOpen, toggleEmployeeModal] = createToggleSignal(false)

    const store = useStore()

    const onOpen = () => {
        store.setFullValidation(true)

        if (!store.canPurchase()) {
            store.startEditing()
            toggleEmployeeModal()
            return
        }

    }

    const onClose = () => {
        toggleEmployeeModal()
        store.stopEditing()
    }

    return (
        <section class={styles.quoteFooter}>
            <PriceBreakdownModal/>
            <div/>
            <Show when={store.getAccount()?.company && store.hasValidSmeLifeCount()}>
                <Button theme="light" onClick={onOpen}>Send to Customer for Review →</Button>
            </Show>
            <ManageMembersModal isOpen={isEmployeeModalOpen()} onDismiss={onClose} />
        </section>
    )
}
