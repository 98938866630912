.mjHH_a_moreInfoModal {
  min-width: 35em;
  max-width: 50em;
  font-size: var(--font-size-small);
  padding: 2em 6em;
}

.mjHH_a_moreInfoModal > section {
  flex-direction: column;
  justify-items: flex-start;
  gap: .6em;
  margin-top: 1em;
  display: flex;
}

.mjHH_a_moreInfoModal ul {
  text-indent: -1.2rem;
  flex-direction: column;
  gap: .3em;
  padding-top: .3em;
  padding-left: 1.5em;
  list-style: inside;
  display: flex;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7QUFPQTs7Ozs7Ozs7QUFRQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvTW9kYWwvTW9yZUluZm9Nb2RhbC5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5tb3JlSW5mb01vZGFsIHtcbiAgICBwYWRkaW5nOiAyZW0gNmVtO1xuICAgIG1heC13aWR0aDogNTBlbTtcbiAgICBtaW4td2lkdGg6IDM1ZW07XG4gICAgZm9udC1zaXplOiB2YXIoLS1mb250LXNpemUtc21hbGwpO1xufVxuXG4ubW9yZUluZm9Nb2RhbCA+IHNlY3Rpb24ge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBqdXN0aWZ5LWl0ZW1zOiBmbGV4LXN0YXJ0O1xuICAgIGdhcDogMC42ZW07XG4gICAgbWFyZ2luLXRvcDogMWVtO1xufVxuXG4ubW9yZUluZm9Nb2RhbCB1bCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGxpc3Qtc3R5bGU6IGRpc2MgaW5zaWRlO1xuICAgIGdhcDogMC4zZW07XG4gICAgcGFkZGluZy10b3A6IDAuM2VtO1xuICAgIHBhZGRpbmctbGVmdDogMS41ZW07XG4gICAgdGV4dC1pbmRlbnQ6IC0xLjJyZW07XG59Il0sIm5hbWVzIjpbXX0= */