import {API} from '@aws-amplify/api'
import {ListSelector, ListSelectorAppearance, TextBox} from '@peachy/client-kit'
import {Company} from '@peachy/core-domain-pure'
import {CompanyLookupService} from '@peachy/lookup-client'
import {debounce} from '@solid-primitives/scheduled'
import {createMemo, createResource, createSignal, mergeProps, onMount, Show} from 'solid-js'
import styles from './CompanyTypeahead.module.css'
import {CompanyMatch} from '@peachy/lookup-pure'
import {PopupListSelector} from '../PopupListSelector/PopupListSelector'
import {classList} from '@peachy/utility-kit-pure'

const Constants = {
    DEBOUNCE_MS: 350,
    MIN_INPUT_LENGTH: 2
}

const companyLookupService = new CompanyLookupService(API)

const companyDisplay = (company: Company) => company ? `${company?.name}, ${company?.address.display}` : ''

export interface CompanyTypeaheadProps {
    company?: Company
    setCompany: (company?: Company) => void
    autoFocus?: boolean
    service?: CompanyLookupService
    placeholder?: string
    popup?: boolean
    appearance ?:ListSelectorAppearance
}

export const CompanyTypeahead = (propsIn: CompanyTypeaheadProps) => {
    const props = mergeProps({service: companyLookupService, placeholder: 'Company name'}, propsIn)

    const search = async (searchTerm: string): Promise<CompanyMatch[]> => {
        if (!searchTerm || searchTerm.length < Constants.MIN_INPUT_LENGTH) {
            return []
        }

        try {
            const response = await props.service.lookup({searchTerm})
            return response.matches
        } catch (e) {
            return []
        }
    }

    const fetch = async (id: string) => {
        console.log('Fetching company', id)
        return props.service.fetch(id)
    }

    const [inputDisplay, setInputDisplay] = createSignal(companyDisplay(props.company))
    const [searchedValue, setSearchedValue] = createSignal('')
    const [suggestions, {mutate: setSuggestions, refetch}] = createResource(searchedValue, search)
    const [showSelector, setShowSelector] = createSignal(false)

    const [textInputElement, setTextInputElement] = createSignal<HTMLInputElement>()
    const loseInputFocus = () => textInputElement().blur()

    onMount(() => {
        if (props.autoFocus && !inputDisplay()) {
            textInputElement().focus()
        }
    })

    const setDebouncedInput = debounce((e: any) => {
        const newValue = e
        setInputDisplay(newValue)
        setSearchedValue(newValue)

        setShowSelector(Boolean(newValue.length))

        ///clear company when input box has been cleared
        if (newValue.trim().length === 0) {
            props.setCompany()
        }
    }, Constants.DEBOUNCE_MS)

    const onInputFocus = () => {
        setInputDisplay(searchedValue())
        setShowSelector(Boolean(searchedValue().length) && searchedValue() !== companyDisplay(props.company))

        refetch()
    }

    const onInputBlur = () => {
        if (companyDisplay(props.company)) {
            setInputDisplay(companyDisplay(props.company))
        }

        setShowSelector(false)
    }

    const onSuggestionSelected = async (suggestion: any, index: number) => {
        const fetchedCompany = await fetch(suggestions()[index].id)

        props.setCompany(fetchedCompany)
        setInputDisplay(companyDisplay(fetchedCompany))

        // clear suggestions
        setSuggestions()
        setShowSelector(false)
        loseInputFocus()
    }

    const onInputHandler = (event: any) => {
        setDebouncedInput(event)
    }

    const onKeyDownHandler = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    const displaySuggestions = () => suggestions()?.length ? suggestions().map(s => s.address) : []
    const showSuggestions = createMemo(() => showSelector() && Boolean(displaySuggestions().length))
    const listSelectorProps = createMemo(() => {
        return {
            list: displaySuggestions(),
            onSelect: onSuggestionSelected,
            onDismiss: () => {
                setShowSelector(false)
                loseInputFocus()
            },
            selection: inputDisplay(),
            enabled: showSuggestions(),
            appearance: {
                listItem: classList(styles.SuggestionsItem, props.appearance?.listItem),
                list: classList(styles.SuggestionsList, props.appearance?.list),
                selectedItem: classList(styles.selectedSuggestions, props.appearance?.selectedItem)
            }
        }
    })

    let labelRef: HTMLLabelElement

    return (
        <div class={styles.CompanyTypeahead}>
            <TextBox
                placeholder={props.placeholder}
                value={inputDisplay()}
                onKeyDown={onKeyDownHandler}
                onInput={onInputHandler}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                inputRef={setTextInputElement}
                ref={labelRef}
            />
            <Show when={showSuggestions()}>
                <Show when={props.popup} fallback={<ListSelector {...listSelectorProps()} />}>
                    <PopupListSelector {...listSelectorProps()} locatorElement={labelRef} />
                </Show>
            </Show>
        </div>
    )
}
