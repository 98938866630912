import {classList} from '@peachy/utility-kit-pure'
import {Modal, ModalProps} from '@peachy/client-kit'
import styles from './MoreInfo.module.css'

export function MoreInfo(props: ModalProps) {
    const modalClasses = classList(styles.moreInfoModal, props.class)

    return <Modal {...props} class={modalClasses}>
        {props.children}
    </Modal>
}
