import {isValidProperty, LifeType, MrAccount, MrLife, MrPolicy, toClass, User, validate} from '@peachy/core-domain-pure'
import {Draft, isAgeBefore, isAgeInRange} from '@peachy/utility-kit-pure'

export const validateUser = (user: User) => validate(toClass(user, User), undefined, User)
export const validateDraftAccount = (account: Draft<MrAccount>) => validate(toClass(account, MrAccount), undefined, MrAccount)

export const MIN_AGE = 16
export const MAX_AGE = 64

export const DEPENDANT_MIN_AGE = 0
export const DEPENDANT_MAX_AGE = 25
export const DEPENDANT_MIN_AGE_EMAIL_REQUIRED = 16

export const LIFE_WARNING_ERROR_MESSAGES = {
    'EMAIL_FORMAT' : 'Please enter a valid email address',
    'EMAIL_UNIQUE': 'Please enter unique email addresses for all members',
    'DOB_DEPENDANT_AGE': `Children must be under ${DEPENDANT_MAX_AGE} on plan start date`,
    'DOB_PRIMARY_SECONDARY_AGE': `Must be between ${MIN_AGE} and ${MAX_AGE} on plan start date`,
    'ADDRESS' : 'Address not recognised'
}

const comparisonStartDate = (subscriptionStartDate: number) => subscriptionStartDate > Date.now() ? subscriptionStartDate : Date.now()

export const isValidDob = (subscriptionStartDate: number, dateOfBirth: number, type: LifeType) => {
    const startDate = comparisonStartDate(subscriptionStartDate)
    switch(type) {
        case 'PRIMARY':
        case 'SECONDARY':
            return isAgeInRange(dateOfBirth, startDate, MIN_AGE, MAX_AGE)
        case 'DEPENDANT':
            return isAgeInRange(dateOfBirth, startDate, DEPENDANT_MIN_AGE, DEPENDANT_MAX_AGE)
        default:
            return false
    }
}

export const validateLifeField = (life: Draft<MrLife>, field: keyof Draft<MrLife>) => isValidProperty(life, field, null, MrLife)

export const validatePolicy = (subscriptionStartDate: number, policy: Draft<MrPolicy>) => {
    const startDate = comparisonStartDate(subscriptionStartDate)

    const validateDob = (life: Draft<MrLife>) => life.dateOfBirth && isValidDob(subscriptionStartDate, life.dateOfBirth, life.type)
    
    const fieldsToValidate = (life: Draft<MrLife>) => {
        if (life.type === 'DEPENDANT' && life.dateOfBirth && isAgeBefore(life.dateOfBirth, startDate, DEPENDANT_MIN_AGE_EMAIL_REQUIRED)) {
            return ['firstname', 'lastname', 'dateOfBirth', 'address']
        }

        return ['firstname', 'lastname', 'dateOfBirth', 'address', 'email']
    }

    return Object.values(policy.lives).every(life => {
        // only validate lives that are newly added... i.e. if they don't have a start date (this is set on the server when added)
        if (life.startDate) {
            return true
        }

        const errorMap = validate(life, null, MrLife)
        const validFields = !fieldsToValidate(life).some(f => errorMap?.[f])

        // TODO Add required annotation to Life Model
        const hasPlanId = life.planId
        const validDob = validateDob(life)

        return validFields && validDob && hasPlanId
    })
}
