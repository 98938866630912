.OqAheW_startDate {
  flex-direction: column;
  gap: 1em;
  padding: 2em 0;
  display: flex;
}

.OqAheW_startDate > div {
  text-align: center;
  flex-direction: row;
  align-items: center;
  column-gap: 1em;
  display: flex;
}

.OqAheW_startDate > div label {
  max-width: 9rem;
}

.OqAheW_startDate i {
  color: var(--peachy-pink);
  cursor: pointer;
  font-size: 1.5em;
}

.OqAheW_startDate i:focus {
  filter: var(--button-focus);
}

.OqAheW_invalidField input[type="text"] {
  border-color: var(--form-error);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7QUFPQTs7Ozs7Ozs7QUFRQTs7OztBQUlBOzs7Ozs7QUFNQTs7OztBQUlBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvY29tcG9uZW50cy9TdGFydERhdGUvU3RhcnREYXRlLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLnN0YXJ0RGF0ZSB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIHBhZGRpbmc6IDJlbSAwO1xuICAgIGdhcDogMWVtXG59XG5cbi5zdGFydERhdGUgPiBkaXYge1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBjb2x1bW4tZ2FwOiAxZW07XG59XG5cbi5zdGFydERhdGUgPiBkaXYgbGFiZWwge1xuICAgIG1heC13aWR0aDogOXJlbTtcbn1cblxuLnN0YXJ0RGF0ZSBpIHtcbiAgICBmb250LXNpemU6IDEuNWVtO1xuICAgIGNvbG9yOiB2YXIoLS1wZWFjaHktcGluayk7XG4gICAgY3Vyc29yOiBwb2ludGVyO1xufVxuXG4uc3RhcnREYXRlIGk6Zm9jdXMge1xuICAgIGZpbHRlcjogdmFyKC0tYnV0dG9uLWZvY3VzKTtcbn1cblxuLmludmFsaWRGaWVsZCBpbnB1dFt0eXBlPXRleHRdIHtcbiAgICBib3JkZXItY29sb3I6IHZhcigtLWZvcm0tZXJyb3IpO1xufVxuIl0sIm5hbWVzIjpbXX0= */