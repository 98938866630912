import {MrPlan, toClass} from '@peachy/core-domain-pure'
import {Draft, Valid} from '@peachy/utility-kit-pure'
import * as PlanConfigJson from './model/PlanConfigJson'

export function isValidPlan(config: PlanConfigJson.Config, planIn: Draft<MrPlan>): planIn is Valid<MrPlan> {
    const configPlan = config.plans.find(p => p.name === planIn.name)

    if (!configPlan) {
        console.log('What the actual?!', config.plans, planIn.name)
    }

    const plan = toClass(planIn, MrPlan)
    const containsMissingBenefits = hasMissingBenefits(config, configPlan, plan)
    const containsUnknownBenefits = hasUnknownBenefits(config, configPlan, plan)
    const containsMissingGroupedBenefits = hasMissingGroupedBenefits(config, configPlan, plan)
    return (!containsMissingBenefits && !containsUnknownBenefits && !containsMissingGroupedBenefits)
}

/**
 * This method ensures that the plan contains the benefits that are always expected to be included.
 * e.g.
 * - any 'boolean' fields that are on by default and cannot be changed, should be present
 * - any 'select' fields that are on by default, should be present
 *
 * @returns true if the plan is missing any of the expected benefits
 */
const hasMissingBenefits = (config: PlanConfigJson.Config, configPlan: PlanConfigJson.Plan, plan: MrPlan) => {
    const expectedBenefitTypes = config.benefits.filter(b => {
        const availableBenefit = configPlan.availableBenefits[b.id]
        return  (
            (b.type === 'boolean' && availableBenefit?.include && !availableBenefit?.isEditable) ||
            (b.type === 'select' && availableBenefit?.include)
        )
    }).flatMap(b => b.benefitTypes)
    return expectedBenefitTypes.filter(expectedBenefitType => !(expectedBenefitType in plan.benefits)).length > 0
}

/**
 * This method ensures that the plan does not include any benefits that are not defined in the configuration
 * e.g. if the config does not have 'VIRTUAL_GP' as a benefit, then the plan should never have 'VIRTUAL_GP' as a benefit.
 *
 * @returns true if the plan contains any unexpected benefits
 */
const hasUnknownBenefits = (config: PlanConfigJson.Config, configPlan: PlanConfigJson.Plan, plan: MrPlan) => {
    const unexpectedBenefitTypes = config.benefits.filter(b => !configPlan.availableBenefits[b.id]).flatMap(b => b.benefitTypes)
    return unexpectedBenefitTypes.filter(unexpectedBenefitType => unexpectedBenefitType in plan.benefits).length !== 0
}

/**
 * This method ensures that for benefits that have multiple benefit types, that if one of the benefit types is included, then all benefit types are included.
 * e.g. if we have a benefit that has benefit types of [ 'DENTAL', 'OPTICAL' ], then if the plan includes 'DENTAL', then it must also include 'OPTICAL'.
 *
 * @returns true if the plan is missing any of the grouped benefits
 */
const hasMissingGroupedBenefits = (config: PlanConfigJson.Config, configPlan: PlanConfigJson.Plan, plan: MrPlan) => {
    const availableGroupedBenefits = config.benefits.filter(b => b.benefitTypes.length > 1 && !!(configPlan.availableBenefits[b.id]))

    const missingGroupedBenefits = availableGroupedBenefits.filter(configBenefit => {
        const benefitTypes = configBenefit.benefitTypes.filter(benefitType => !(benefitType in plan.benefits))
        return (benefitTypes.length !== 0 && benefitTypes.length !== configBenefit.benefitTypes.length)
    })

    return missingGroupedBenefits.length > 0
}
