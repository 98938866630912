.ps_Ypq_whatsNotCovered {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  gap: 3em;
  width: 100vw;
  margin-top: 2em;
  padding: 4em 8em;
  display: flex;
}

.ps_Ypq_whatsNotCovered ul {
  flex-direction: column;
  row-gap: 1em;
  width: 100%;
  max-width: 1600px;
  display: flex;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7O0FBV0EiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9jb21wb25lbnRzL1doYXRzTm90Q292ZXJlZC9XaGF0c05vdENvdmVyZWQubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIud2hhdHNOb3RDb3ZlcmVkIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGdhcDozZW07XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIHBhZGRpbmc6IDRlbSA4ZW07XG4gICAgbWFyZ2luLXRvcDogMmVtO1xuICAgIHdpZHRoOiAxMDB2dztcbn1cblxuLndoYXRzTm90Q292ZXJlZCB1bCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIHJvdy1nYXA6IDFlbTtcbiAgICBtYXgtd2lkdGg6IDE2MDBweDtcbiAgICB3aWR0aDogMTAwJTtcbn1cbiJdLCJuYW1lcyI6W119 */