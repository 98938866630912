.yb97Yq_Footer {
  color: #fff;
  font-family: var(--display-font);
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100vw;
  height: 90px;
  max-height: 90px;
  display: grid;
  position: sticky;
  bottom: 0;
}

.yb97Yq_Footer label {
  text-transform: uppercase;
  margin-right: 10px;
  font-size: 2rem;
}

.yb97Yq_Footer > div {
  padding: 0 4em 0 2em;
}

.yb97Yq_Footer .yb97Yq_CurrentPrice {
  background-color: var(--primary-color-1);
  align-items: center;
  display: flex;
}

.yb97Yq_Footer .yb97Yq_NewPrice {
  background-color: var(--peachy-purple);
  justify-content: space-between;
  align-items: center;
  padding-right: 5em;
  display: flex;
}

.yb97Yq_Footer .yb97Yq_NewPrice button {
  padding-left: 2em;
  padding-right: 2em;
}

.yb97Yq_ProceedButton {
  background-color: #99579a;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7OztBQWNBOzs7Ozs7QUFNQTs7OztBQUlBOzs7Ozs7QUFNQTs7Ozs7Ozs7QUFTQTs7Ozs7QUFLQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL3BhZ2VzL2Rhc2hib2FyZC9Zb3VyUGxhbi9RdW90ZUZvb3Rlci9RdW90ZUZvb3Rlci5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5Gb290ZXIge1xuICAgIHBvc2l0aW9uOiBzdGlja3k7XG4gICAgYm90dG9tOiAwO1xuICAgIGNvbG9yOiB3aGl0ZTtcbiAgICBmb250LWZhbWlseTogdmFyKC0tZGlzcGxheS1mb250KTtcblxuICAgIHdpZHRoOiAxMDB2dztcbiAgICBtYXgtaGVpZ2h0OiA5MHB4O1xuICAgIGhlaWdodDogOTBweDtcblxuICAgIGRpc3BsYXk6IGdyaWQ7XG4gICAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiByZXBlYXQoYXV0by1maXQsIG1pbm1heCgyMDBweCwgMWZyKSk7XG59XG5cbi5Gb290ZXIgbGFiZWwge1xuICAgIGZvbnQtc2l6ZTogMnJlbTtcbiAgICB0ZXh0LXRyYW5zZm9ybTogdXBwZXJjYXNlO1xuICAgIG1hcmdpbi1yaWdodDogMTBweDtcbn1cblxuLkZvb3RlciA+IGRpdiB7XG4gICAgcGFkZGluZzogMCA0ZW0gMCAyZW07XG59XG5cbi5Gb290ZXIgLkN1cnJlbnRQcmljZSB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tcHJpbWFyeS1jb2xvci0xKTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG5cbi5Gb290ZXIgLk5ld1ByaWNlIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1wZWFjaHktcHVycGxlKTtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuXG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gICAgcGFkZGluZy1yaWdodDogNWVtO1xufVxuXG4uRm9vdGVyIC5OZXdQcmljZSBidXR0b24ge1xuICAgIHBhZGRpbmctbGVmdDogMmVtO1xuICAgIHBhZGRpbmctcmlnaHQ6IDJlbTtcbn1cblxuLlByb2NlZWRCdXR0b24ge1xuICAgIGJhY2tncm91bmQtY29sb3I6ICM5OTU3OWE7XG59XG4iXSwibmFtZXMiOltdfQ== */