.rQe0Iq_Summary {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.rQe0Iq_Summary section {
  padding: 2em 0;
}

.rQe0Iq_Summary section:last-child {
  padding-bottom: 5em;
}

.rQe0Iq_WhiteSection {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  display: flex;
}

.rQe0Iq_Heading {
  padding-top: 3em;
}

.rQe0Iq_DetailsContainer {
  width: 470px;
}

.rQe0Iq_WhiteSection p, #rQe0Iq_payment-form span:first-of-type, .rQe0Iq_StartDate {
  font-size: var(--font-size-root-xsmall);
}

.rQe0Iq_PlanConfig {
  font-size: var(--font-size-small);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7OztBQU1BOzs7O0FBSUE7Ozs7QUFJQTs7Ozs7Ozs7QUFRQTs7OztBQUlBOzs7O0FBTUE7Ozs7QUFNQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL3BhZ2VzL3F1b3RlL1N1bW1hcnkvU3VtbWFyeS5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5TdW1tYXJ5IHtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbn1cblxuLlN1bW1hcnkgc2VjdGlvbiB7XG4gICAgcGFkZGluZzogMmVtIDAgMmVtIDA7XG59XG5cbi5TdW1tYXJ5IHNlY3Rpb246bGFzdC1jaGlsZCB7XG4gICAgcGFkZGluZy1ib3R0b206IDVlbTtcbn1cblxuLldoaXRlU2VjdGlvbiB7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgd2lkdGg6IDEwMHZ3O1xufVxuXG4uSGVhZGluZyB7XG4gICAgcGFkZGluZy10b3A6IDNlbTtcbn1cblxuLkRldGFpbHNDb250YWluZXIge1xuICAgIHdpZHRoOiA0NzBweDtcbn1cblxuLyogPT09IEZPTlQgU0laRVMgPT09ICovXG5cbi5XaGl0ZVNlY3Rpb24gcCxcbiNwYXltZW50LWZvcm0gc3BhbjpudGgtb2YtdHlwZSgxKSxcbi5TdGFydERhdGUge1xuICAgIGZvbnQtc2l6ZTogdmFyKC0tZm9udC1zaXplLXJvb3QteHNtYWxsKTtcbn1cblxuLlBsYW5Db25maWcge1xuICAgIGZvbnQtc2l6ZTogdmFyKC0tZm9udC1zaXplLXNtYWxsKTtcbn1cbiJdLCJuYW1lcyI6W119 */