import {Component} from 'solid-js'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {PlanConfiguration} from '../../../components/PlanBuilder/PlanConfiguration/PlanConfiguration'
import {PlanMembersCounter} from './PlanMemberCounter'
import {Life} from '@peachy/core-domain-pure'
import {usePlanConfig} from '../../../config/configureApp'

export const QuoteSummary: Component = () => {
    const store = useStore(), planConfig = usePlanConfig()

    store.setPlanEditable(false)
    store.setViewMode('QUOTE')

    return (<>
        <PlanConfiguration
            planConfig={planConfig}
            plans={store.getPlans()}
            lives={store.getLives() as Life[]}
        />
        <PlanMembersCounter/>
    </>)
}
