.qLHD5G_Toggle {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}

.qLHD5G_Toggle > span {
  color: #000;
}

.qLHD5G_Toggle > input {
  appearance: none;
  cursor: pointer;
  box-sizing: content-box;
  background-color: #d3d3d3;
  border-radius: 2em;
  outline: none;
  width: 1.5em;
  height: 1em;
  transition: all .2s;
  position: relative;
}

.qLHD5G_Toggle > input:before {
  content: " ";
  background-color: #fff;
  border-radius: 1em;
  width: 50%;
  padding-top: 50%;
  transition: all .2s;
  position: absolute;
  top: 50%;
  left: 2%;
  transform: translate(0, -51%);
}

.qLHD5G_Toggle > input:checked:before {
  left: 47%;
}

.qLHD5G_Toggle > input:checked {
  background-color: #98fb98;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7QUFPQTs7OztBQUlBOzs7Ozs7Ozs7Ozs7O0FBYUE7Ozs7Ozs7Ozs7Ozs7QUFhQTs7OztBQUlBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9jbGllbnQta2l0L3NyYy9jb21wb25lbnRzL1RvZ2dsZS9Ub2dnbGUubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuVG9nZ2xlIHtcbiAgICBkaXNwbGF5OiBpbmxpbmUtZmxleDtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG4gICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG5cbi5Ub2dnbGUgPiBzcGFuIHtcbiAgICBjb2xvcjogYmxhY2s7XG59XG5cbi5Ub2dnbGUgPiBpbnB1dCB7XG4gICAgcG9zaXRpb246IHJlbGF0aXZlO1xuICAgIGFwcGVhcmFuY2U6IG5vbmU7XG4gICAgb3V0bGluZTogbm9uZTtcbiAgICBoZWlnaHQ6IDFlbTtcbiAgICB3aWR0aDogMS41ZW07XG4gICAgYm9yZGVyLXJhZGl1czogMmVtO1xuICAgIHRyYW5zaXRpb246IDIwMG1zIGFsbDtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiBsaWdodGdyYXk7XG4gICAgY3Vyc29yOiBwb2ludGVyO1xuICAgIGJveC1zaXppbmc6IGNvbnRlbnQtYm94O1xufVxuXG4uVG9nZ2xlID4gaW5wdXQ6OmJlZm9yZSB7XG4gICAgY29udGVudDogJyAnO1xuICAgIHRvcDogNTAlO1xuICAgIGxlZnQ6IDIlO1xuICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlKDAsIC01MSUpO1xuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgICB3aWR0aDogNTAlO1xuICAgIHBhZGRpbmctdG9wOiA1MCU7XG4gICAgYm9yZGVyLXJhZGl1czogMWVtO1xuICAgIGJhY2tncm91bmQtY29sb3I6IHdoaXRlO1xuICAgIHRyYW5zaXRpb246IDIwMG1zIGFsbDtcbn1cblxuLlRvZ2dsZSA+IGlucHV0OmNoZWNrZWQ6OmJlZm9yZSB7XG4gICAgbGVmdDogNDclO1xufVxuXG4uVG9nZ2xlID4gaW5wdXQ6Y2hlY2tlZCB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogcGFsZWdyZWVuO1xufSJdLCJuYW1lcyI6W119 */