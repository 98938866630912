import PeachySvg from './src/peachy.svg'
import SamplePlanSvg from './src/sample-plan-tab.svg'
import PartyPopper from './src/party-popper.svg'
import IpidDoc from './src/ipid.pdf'
import PddDoc from './src/pdd.pdf'
import SmeIpidDoc from './src/sme-ipid.pdf'
import SmePddDoc from './src/sme-pdd.pdf'
import BookIcon from './src/icons/book.svg'
import DownloadCloudIcon from './src/icons/download-cloud.svg'
import ExcessIcon from './src/icons/excess.svg'
import AdviceCounsellingIcon from './src/icons/benefit/advice-counselling.svg'
import ConsultationsDiagnosticsIcon from './src/icons/benefit/consultations-diagnostics.svg'
import DentalOpticalIcon from './src/icons/benefit/dental-optical.svg'
import HospitalCareIcon from './src/icons/benefit/hospital-care.svg'
import MentalHealthIcon from './src/icons/benefit/mental-health.svg'
import TherapiesIcon from './src/icons/benefit/therapies.svg'
import VirtualGpIcon from './src/icons/benefit/virtual-gp.svg'
import CrossIcon from './src/icons/cross.svg'


export {PeachySvg}
export {SamplePlanSvg}
export {PartyPopper}

export {CrossIcon}

export {IpidDoc, PddDoc, SmeIpidDoc, SmePddDoc}

export {BookIcon}
export {DownloadCloudIcon}
export {ExcessIcon}
export {AdviceCounsellingIcon}
export {ConsultationsDiagnosticsIcon}
export {DentalOpticalIcon}
export {HospitalCareIcon}
export {MentalHealthIcon}
export {TherapiesIcon}
export {VirtualGpIcon}







        import * as me from '@peachy/assets'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src2938690931/src/codestar-connections.eu-west-2.amazonaws.com/git-http/879656270406/eu-west-2/5886de9c-5c2f-4d33-b265-d0ba742d8141/peachy-punnet/peachy-mono-repo/comp-kit/assets'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/assets'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    