.bSsU2q_invalidField input, .bSsU2q_invalidField select {
  border-color: var(--error);
}

.bSsU2q_invalidField .bSsU2q_message {
  color: var(--error);
}

.bSsU2q_warningField input, .bSsU2q_warningField select {
  border-color: var(--warning);
}

.bSsU2q_warningField .bSsU2q_message {
  color: var(--warning);
}

.bSsU2q_message {
  white-space: nowrap;
  background: #fff;
  padding-left: 2px;
  padding-right: 2px;
  font-size: small;
  position: absolute;
  bottom: -6px;
  right: 8px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7QUFLQTs7OztBQUlBOzs7O0FBS0E7Ozs7QUFJQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vY2xpZW50LWtpdC9zcmMvY29tcG9uZW50cy9FcnJvcldyYXBwZWQubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuaW52YWxpZEZpZWxkIGlucHV0LFxuLmludmFsaWRGaWVsZCBzZWxlY3Qge1xuICAgIGJvcmRlci1jb2xvcjogdmFyKC0tZXJyb3IpO1xufVxuXG4uaW52YWxpZEZpZWxkIC5tZXNzYWdlIHtcbiAgICBjb2xvcjogIHZhcigtLWVycm9yKTtcbn1cblxuLndhcm5pbmdGaWVsZCBpbnB1dCwgXG4ud2FybmluZ0ZpZWxkIHNlbGVjdCB7XG4gICAgYm9yZGVyLWNvbG9yOiB2YXIoLS13YXJuaW5nKTtcbn1cblxuLndhcm5pbmdGaWVsZCAubWVzc2FnZSB7XG4gICAgY29sb3I6ICB2YXIoLS13YXJuaW5nKTtcbn1cblxuLm1lc3NhZ2Uge1xuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgICBib3R0b206IC02cHg7XG4gICAgcmlnaHQ6IDhweDtcbiAgICBmb250LXNpemU6IHNtYWxsO1xuICAgIHBhZGRpbmctbGVmdDogMnB4O1xuICAgIHBhZGRpbmctcmlnaHQ6IDJweDtcbiAgICBiYWNrZ3JvdW5kOiB3aGl0ZTtcbiAgICB3aGl0ZS1zcGFjZTogbm93cmFwO1xufSJdLCJuYW1lcyI6W119 */