import {Page} from '../../Page'
import {DashboardHeader} from '../Header/DashboardHeader/DashboardHeader'
import {getAccountSubscription, getSubscriptionRequest} from '../../providers/AccountProvider'
import {useNavigate} from '@solidjs/router'
import {useIamService} from '../../providers/AppServiceProvider'

export default function Home (props) {

    const navigate = useNavigate(), iamService = useIamService()

    // not signed in - forward to the quote page
    if (!iamService.isSignedIn()) {
        navigate('/quote', { replace: true })
        return
    }

    // in progress quote - forward to the quote plans page
    if (!getAccountSubscription() && getSubscriptionRequest()) {
        navigate('/quote/plans', { replace: true })
        return
    }
    // cancelled subscription - forward to the billing page
    if(getAccountSubscription()?.subscription.status === 'CANCELLED') {
        navigate('/billing', { replace: true })
        return
    }

    return (
        <Page header={<DashboardHeader />}>
            {props.children}
        </Page>
    )
}
