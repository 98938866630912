.l790HW_planMembers {
  width: 100%;
}

.l790HW_member {
  width: var(--col-width-plan);
  background-color: var(--surface-color);
  text-align: center;
  pointer-events: none;
  border-radius: 10px;
  height: 3em;
  font-weight: bold;
  line-height: 3em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7QUFJQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL3BhZ2VzL3F1b3RlL1N1bW1hcnkvUGxhbk1lbWJlckNvdW50ZXIubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIucGxhbk1lbWJlcnMge1xuICAgIHdpZHRoOiAxMDAlO1xufVxuXG4ubWVtYmVyIHtcbiAgICB3aWR0aDogdmFyKC0tY29sLXdpZHRoLXBsYW4pO1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXN1cmZhY2UtY29sb3IpO1xuICAgIGJvcmRlci1yYWRpdXM6IDEwcHg7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICAgIGhlaWdodDogM2VtO1xuICAgIGxpbmUtaGVpZ2h0OiAzZW07XG4gICAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gICAgcG9pbnRlci1ldmVudHM6IG5vbmU7XG59Il0sIm5hbWVzIjpbXX0= */