import styles from './AnnualLimitInfo.module.css'
import {createToggleSignal} from '@peachy/client-kit'
import {DismissibleModal} from '../Modal/DismissibleModal'

export const AnnualLimitInfo = () => {

    const [isOpen, toggleIsOpen] = createToggleSignal(false)

    return (
        <>
            <span class={styles.annualLimitInfo}>
                Not sure what limits to choose? See our <mark onClick={toggleIsOpen}>annual limit FAQs</mark> for some examples of treatment prices.
            </span>
            <DismissibleModal isOpen={isOpen()} onDismiss={toggleIsOpen} class={styles.annualLimitInfoModal}>
                <section>
                    <h5>What are annual limits?</h5>
                    <p>Depending on how you craft your plan, some benefits may come with annual limits. An annual limit lasts for 12 months from the cover start date and refreshes every 12 months thereafter – assuming you remain on continuous cover with Peachy from the start of your plan.</p>

                    <h5>What annual limit should I choose?</h5>

                    <p>We are not able to offer advice on what annual limits you should choose when crafting your plan however, we can provide you with information which can help you make a decision.</p>

                    <h6>For Consultations & diagnostics</h6>

                    <p>Here are some examples of how much private consultations & diagnostic tests can cost:</p>

                    <dl>
                        <dt>Initial consultation</dt>
                        <dd>£170 – £280</dd>

                        <dt>Colonoscopy</dt>
                        <dd>£2,065 – £2,885</dd>

                        <dt>Gastroscopy</dt>
                        <dd>£1,655 – £2,570</dd>

                        <dt>MRI scan (1 body)</dt>
                        <dd>£390 - £640</dd>
                    </dl>
                    <cite>(Source: PHIN 2019, Nuffield Healthcare Aug 2021)</cite>

                    <h6>For Hospital care</h6>

                    <p>Here are some examples of how much private medical procedures treatments can cost:</p>

                    <dl>
                        <dt>Hip replacement</dt>
                        <dd>£10,195 – £15,625</dd>

                        <dt>Knee replacement</dt>
                        <dd>£10,195 – £16,795</dd>

                        <dt>Slipped disc removal (lower back)</dt>
                        <dd>£6,629 – £14,750</dd>

                        <dt>Knee surgery (ACL repair)</dt>
                        <dd>£6,400</dd>

                        <dt>Gall bladder removal (keyhole surgery)</dt>
                        <dd>£5,195 – £8,010</dd>

                        <dt>Shoulder decompression</dt>
                        <dd>£5,115</dd>

                        <dt>Key arthroscopy</dt>
                        <dd>£3,070 – £4,575</dd>

                        <dt>Spinal injection</dt>
                        <dd>£2,085 – £3,285</dd>

                        <dt>Carpal tunnel release</dt>
                        <dd>£1,945 – £2,720</dd>
                    </dl>
                    <cite>(Source: Nuffield Healthcare Aug 2021)</cite>
                </section>
            </DismissibleModal>
        </>
    )
}
