._9B-pdq_captureForm {
  flex-direction: column;
  align-items: start;
  row-gap: 1.5em;
  width: 29em;
  padding: 2em;
  display: flex;
}

._9B-pdq_captureForm fieldset {
  align-self: stretch;
  column-gap: 1em;
  display: flex;
}

._9B-pdq_captureForm button {
  align-self: center;
  margin-top: 1em;
}

._9B-pdq_captureForm input:focus {
  border-color: var(--form-highlight);
}

._9B-pdq_captureForm nav {
  justify-content: space-between;
  width: 100%;
  font-size: .9em;
  display: flex;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQ0E7Ozs7Ozs7OztBQVNBOzs7Ozs7QUFNQTs7Ozs7QUFLQTs7OztBQUlBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvcGFnZXMvcXVvdGUvQ2FwdHVyZUZvcm0ubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyJcbi5jYXB0dXJlRm9ybSB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGFsaWduLWl0ZW1zOiBzdGFydDtcbiAgICByb3ctZ2FwOiAxLjVlbTtcbiAgICB3aWR0aDogMjllbTtcbiAgICBwYWRkaW5nOiAyZW07XG59XG5cbi5jYXB0dXJlRm9ybSBmaWVsZHNldCB7XG4gICAgYWxpZ24tc2VsZjogc3RyZXRjaDtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGNvbHVtbi1nYXA6IDFlbTtcbn1cblxuLmNhcHR1cmVGb3JtIGJ1dHRvbiB7XG4gICAgbWFyZ2luLXRvcDogMWVtO1xuICAgIGFsaWduLXNlbGY6IGNlbnRlcjtcbn1cblxuLmNhcHR1cmVGb3JtIGlucHV0OmZvY3VzIHtcbiAgICBib3JkZXItY29sb3I6IHZhcigtLWZvcm0taGlnaGxpZ2h0KTtcbn1cblxuLmNhcHR1cmVGb3JtIG5hdiB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gICAgZm9udC1zaXplOiAwLjllbTtcbn1cbiJdLCJuYW1lcyI6W119 */