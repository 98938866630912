._BEOFq_FormattedPrice {
  font-family: var(--display-font);
  text-transform: uppercase;
  filter: blur();
  opacity: 1;
  transition: all .3s;
  display: inline-block;
  transform: scale(1);
}

._BEOFq_FormattedPrice > span:first-child {
  font-size: 2.5em;
}

._BEOFq_FormattedPrice > span:nth-child(2) {
  font-size: 1.5em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7QUFVQTs7OztBQUlBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvY29tcG9uZW50cy9Gb3JtYXR0ZWRQcmljZS9Gb3JtYXR0ZWRQcmljZS5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5Gb3JtYXR0ZWRQcmljZSB7XG4gICAgZm9udC1mYW1pbHk6IHZhcigtLWRpc3BsYXktZm9udCk7XG4gICAgdGV4dC10cmFuc2Zvcm06IHVwcGVyY2FzZTtcbiAgICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG4gICAgdHJhbnNpdGlvbjogYWxsIDMwMG1zO1xuICAgIGZpbHRlcjogYmx1cigwKTtcbiAgICBvcGFjaXR5OiAxO1xuICAgIHRyYW5zZm9ybTogc2NhbGUoMSk7XG59XG5cbi5Gb3JtYXR0ZWRQcmljZSA+IHNwYW46Zmlyc3QtY2hpbGQge1xuICAgIGZvbnQtc2l6ZTogMi41ZW07XG59XG5cbi5Gb3JtYXR0ZWRQcmljZSA+IHNwYW46bnRoLWNoaWxkKDIpIHtcbiAgICBmb250LXNpemU6IDEuNWVtO1xufVxuIl0sIm5hbWVzIjpbXX0= */