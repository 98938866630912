import {A} from '@solidjs/router'
import {FAQS_URL, MyQuotesPath} from '../../../App'
import {PeachyLogo} from '../../../global/assets/PeachyLogo/PeachyLogo'
import {Header, LogoutLink} from '../Header'
import {getSubscription} from '../../../providers/AccountProvider'
import {createMemo, Show} from 'solid-js'
import {canManageQuotes} from '../../../providers/Permissions/PermissionsProvider'
import styles from './DashboardHeader.module.css'


export function DashboardHeader() {
    const isCancelled = createMemo(() => getSubscription().status === 'CANCELLED')

    return <Header class={styles.header}>
        <div>
            <Show when={!isCancelled()} fallback={<span class={styles.headerDisabled}>Your Plan</span>}>
                <A href={'/'}>Your Plan</A>
            </Show>
            <Show when={!isCancelled()} fallback={<span class={styles.headerDisabled}>Documents</span>}>
                <A href={'/documents'}>Documents</A>
            </Show>
            <A href={'/billing'}>Billing</A>
            <Show when={!isCancelled()} fallback={<span class={styles.headerDisabled}>Members</span>}>
                <A href={'/members'}>Members</A>
            </Show>
            <Show when={canManageQuotes()}>
                <A href={MyQuotesPath}>My Quotes</A>
            </Show>
        </div>
        <PeachyLogo/>
        <div>
            <A href={FAQS_URL} target={'_blank'}>FAQs</A>
            <LogoutLink />
        </div>
    </Header>
}
