const Permission = {
    MULTIPLE_QUOTES: 'permission:allow_multiple_quotes'
}

export class Permissions {
    constructor (private readonly token: unknown) {}

    canManageQuotes() {
        return this.hasPermission(Permission.MULTIPLE_QUOTES)
    }

    private hasPermission(permission: string) {
        return this.token?.[permission] === 'true'
    }
}
