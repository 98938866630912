.oC6sXq_header > div {
  column-gap: 2em;
  display: flex;
}

.oC6sXq_header > div:first-of-type {
  margin-right: auto;
  padding-left: 2em;
}

.oC6sXq_header > div:last-of-type {
  margin-left: auto;
  padding-right: 2em;
}

.oC6sXq_header a, .oC6sXq_headerDisabled {
  color: unset;
  font-size: 1rem;
  font-weight: normal;
}

.oC6sXq_headerDisabled {
  opacity: .3;
  cursor: default;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0E7Ozs7O0FBS0E7Ozs7O0FBS0E7Ozs7OztBQUtBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvY29tcG9uZW50cy9IZWFkZXIvRGFzaGJvYXJkSGVhZGVyL0Rhc2hib2FyZEhlYWRlci5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5oZWFkZXIgPiBkaXYge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgY29sdW1uLWdhcDogMmVtO1xufVxuXG4uaGVhZGVyID4gZGl2OmZpcnN0LW9mLXR5cGUge1xuICAgIG1hcmdpbi1yaWdodDogYXV0bztcbiAgICBwYWRkaW5nLWxlZnQ6IDJlbTtcbn1cblxuLmhlYWRlciA+IGRpdjpsYXN0LW9mLXR5cGUge1xuICAgIG1hcmdpbi1sZWZ0OiBhdXRvO1xuICAgIHBhZGRpbmctcmlnaHQ6IDJlbTtcbn1cblxuLmhlYWRlciBhLCAuaGVhZGVyRGlzYWJsZWQge1xuICAgIGZvbnQtc2l6ZTogMXJlbTtcbiAgICBmb250LXdlaWdodDogbm9ybWFsO1xuICAgIGNvbG9yOiB1bnNldDtcbn1cbi5oZWFkZXJEaXNhYmxlZCB7XG4gICAgb3BhY2l0eTogMC4zO1xuICAgIGN1cnNvcjogZGVmYXVsdDtcbn0iXSwibmFtZXMiOltdfQ== */