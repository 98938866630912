.POksLq_PopupListSelector {
  z-index: 1;
  position: absolute;
}

.POksLq_list {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  max-height: 10em;
  padding: .2em 0;
}

ul.POksLq_placeholder {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

p.POksLq_placeholder {
  color: #b0b8c7;
  user-select: none;
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  justify-content: space-between;
  display: flex;
}

p.POksLq_placeholder > i {
  color: #000;
  margin-top: 5px;
}

p.POksLq_placeholder:hover {
  cursor: default;
  background-color: #fff;
}

.POksLq_list ul > li {
  padding: .2em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0E7Ozs7Ozs7QUFPQTs7Ozs7O0FBTUE7Ozs7Ozs7Ozs7QUFVQTs7Ozs7QUFLQTs7Ozs7QUFLQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vY2xpZW50LWtpdC9zcmMvY29tcG9uZW50cy9Qb3B1cExpc3RTZWxlY3Rvci9Qb3B1cExpc3RTZWxlY3Rvci5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5Qb3B1cExpc3RTZWxlY3RvciB7XG4gICAgei1pbmRleDogMTtcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG59XG5cbi5saXN0IHtcbiAgICBtYXgtaGVpZ2h0OiAxMGVtO1xuICAgIGJhY2tncm91bmQtY29sb3I6IHdoaXRlO1xuICAgIGJvcmRlcjogMXB4IHNvbGlkIGxpZ2h0Z3JheTtcbiAgICBwYWRkaW5nOiAwLjJlbSAwO1xufVxuXG51bC5wbGFjZWhvbGRlciB7XG4gICAgYm9yZGVyLXRvcDogMDtcbiAgICBib3JkZXItdG9wLWxlZnQtcmFkaXVzOiAwO1xuICAgIGJvcmRlci10b3AtcmlnaHQtcmFkaXVzOiAwO1xufVxuXG5wLnBsYWNlaG9sZGVyIHtcbiAgICBib3JkZXItYm90dG9tOiAwO1xuICAgIGJvcmRlci1ib3R0b20tbGVmdC1yYWRpdXM6IDA7XG4gICAgYm9yZGVyLWJvdHRvbS1yaWdodC1yYWRpdXM6IDA7XG4gICAgY29sb3I6ICNCMEI4Qzc7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gICAgdXNlci1zZWxlY3Q6IG5vbmU7XG59XG5cbnAucGxhY2Vob2xkZXIgPiBpIHtcbiAgICBtYXJnaW4tdG9wOiA1cHg7XG4gICAgY29sb3I6IGJsYWNrO1xufVxuXG5wLnBsYWNlaG9sZGVyOmhvdmVyIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICBjdXJzb3I6IGRlZmF1bHRcbn1cblxuLmxpc3QgdWw+bGkge1xuICAgIHBhZGRpbmc6IDAuMmVtO1xufSJdLCJuYW1lcyI6W119 */