.SIHd2a_Contents {
  flex-direction: column;
  gap: 1em;
  display: flex;
}

.SIHd2a_Details {
  grid-template-columns: 10em 1fr;
  display: inline-grid;
}

.SIHd2a_Details p {
  line-height: unset;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7OztBQU1BOzs7OztBQUtBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvcGFnZXMvcXVvdGUvU3VtbWFyeS9CdXNpbmVzc0RldGFpbHMubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuQ29udGVudHMge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZ2FwOiAxZW07XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbn1cblxuLkRldGFpbHMge1xuICAgIGRpc3BsYXk6IGlubGluZS1ncmlkO1xuICAgIGdyaWQtdGVtcGxhdGUtY29sdW1uczogMTBlbSAxZnI7XG59XG5cbi5EZXRhaWxzIHAge1xuICAgIGxpbmUtaGVpZ2h0OiB1bnNldDtcbn0iXSwibmFtZXMiOltdfQ== */