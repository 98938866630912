._0wqURG_list {
  border: 2px solid var(--peachy-pink);
  font-size: var(--font-size-small);
  border-radius: .5em;
  overflow-y: scroll;
}

._0wqURG_listItem {
  padding: .2em .5em;
}

._0wqURG_listItem:hover {
  background-color: var(--form-hover);
  cursor: pointer;
}

._0wqURG_selectedItem {
  color: #fff;
}

._0wqURG_selectedItem, ._0wqURG_selectedItem:hover {
  background-color: var(--peachy-pink);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7QUFPQTs7OztBQUlBOzs7OztBQUtBOzs7O0FBSUEiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9jb21wb25lbnRzL1BvcHVwTGlzdFNlbGVjdG9yL1BvcHVwTGlzdFNlbGVjdG9yLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLmxpc3Qge1xuICAgIGJvcmRlcjogMnB4IHNvbGlkIHZhcigtLXBlYWNoeS1waW5rKTtcbiAgICBib3JkZXItcmFkaXVzOiAwLjVlbTtcbiAgICBmb250LXNpemU6IHZhcigtLWZvbnQtc2l6ZS1zbWFsbCk7XG4gICAgb3ZlcmZsb3cteTogc2Nyb2xsO1xufVxuXG4ubGlzdEl0ZW0ge1xuICAgIHBhZGRpbmc6IDAuMmVtIDAuNWVtO1xufVxuXG4ubGlzdEl0ZW06aG92ZXIge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWZvcm0taG92ZXIpO1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbn1cblxuLnNlbGVjdGVkSXRlbSB7XG4gICAgY29sb3I6IHdoaXRlO1xufVxuXG4uc2VsZWN0ZWRJdGVtLCAuc2VsZWN0ZWRJdGVtOmhvdmVyIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1wZWFjaHktcGluayk7XG59XG4iXSwibmFtZXMiOltdfQ== */