import {API} from '@aws-amplify/api'
import {Auth} from '@aws-amplify/auth'
import {useServicePatch} from '@peachy/client-kit'
import {configureApiGateway, fetchServiceConfig} from '@peachy/core-domain-client'


//todo: put these names somewhere else as they are used in multiple places
const LookupApiName = 'LookupApi'
const PaymentsApiName = 'PaymentsApi'
const IamPortalApiName = 'IamPortalApi'
const IntercomApiName = 'Intercom'

const region = 'eu-west-2'

export async function configureAws() {

    const servicePatchUrl = useServicePatch()

    //auth config
    const iamConfig = await fetchServiceConfig('IamPortalConfig', servicePatchUrl)

    Auth.configure({
        userPoolId: iamConfig.userPoolId,
        userPoolWebClientId: iamConfig.userPoolWebClientId,
        identityPoolId: iamConfig.identityPoolId,
        region,
    })

    await configureApiGateway(LookupApiName, API)
    await configureApiGateway(PaymentsApiName, API)
    await configureApiGateway(IamPortalApiName, API)
    await configureApiGateway(IntercomApiName, API, {auth: Auth})
}
