import {MrPolicy, MrSubscription} from '@peachy/core-domain-pure'
import {Component} from 'solid-js'
import {InfoPanelPlanStartDate} from '../pure/InfoPanelPlanStartDate'
import {InfoPanel} from '../pure/InfoPanel'

export const YourPlanPolicyDetailsPanel: Component<{ subscription: MrSubscription, policies: MrPolicy[] }> = (props) => {
    const totalMembers = () => props.policies.flatMap(p => Object.values(p.lives))?.length

    return (
        <InfoPanel>
            <h5>Policy: {props.subscription.referenceNumber()}</h5>
            <InfoPanelPlanStartDate startDate={new Date(props.subscription.startDate)} />
            <p>You currently have <span>{totalMembers()} <MemberTextDisplay noOfMembers={totalMembers()} /></span> on plan</p>
        </InfoPanel>
    )
}

export const MemberTextDisplay: Component<{noOfMembers : number}> = (props) => <>member{props.noOfMembers > 1 ? 's' : ''}</>
