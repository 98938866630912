// import type { Equals } from './utility-types'

export type AppendTuple<T extends any[], E> = [...T, E]
export type PrependTuple<T extends any[], E> = [E, ...T]
export type ConcatTuple<T1 extends any[], T2 extends any[]> = [...T1, ...T2]
export type ShiftTuple<T extends any[]> = T extends [T[0], ...infer R] ? R : never
export type PopTuple<T extends any[]> = T extends [...infer R, unknown] ? R : never
export type LastTuple<T extends any[]> = T extends [...unknown[], infer E] ? E : never

//tests!
// const canAppendTuple: Equals<
//     AppendTuple<[number, string], number>,
//     [number, string, number]
// > = true

// const canPrependTuple: Equals<
//     PrependTuple<[number, string], number>,
//     [number, number, string]
// > = true

// const canConcatTuple: Equals<
//     ConcatTuple<[number, string], [number, boolean]>,
//     [number, string, number, boolean]
// > = true


// const canShiftTuple: Equals<
//     ShiftTuple<[number, string, boolean]>,
//     [string, boolean]
// > = true

// const canPopTuple: Equals<
//     PopTuple<[number, string, boolean]>,
//     [number, string]
// > = true
