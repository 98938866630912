import {MrPlan} from '@peachy/core-domain-pure'
import {Component, For} from 'solid-js'
import {classList} from '@peachy/utility-kit-pure'
import {BookIcon, DownloadCloudIcon} from '@peachy/assets'
import {PlanCard} from '../PlanConfiguration/PlanConfiguration'
import styles from '../PlanConfiguration/PlanConfiguration.module.css'
import downloadStyles from './PlanDownloads.module.css'


type PlanDownloadsProps = {
    plans: MrPlan[]
    optionLabel: string
    onDownload: (plan: MrPlan) => void
}

const PlanDownloadLabel: Component<{ label: string }> = (props) => {
    return (
        <div class={classList(styles.card, styles.labelCard)}>
            <div class={classList(styles.label, downloadStyles.label)}>
                <span><BookIcon /></span>
                <span class={styles.name}>{props.label}</span>
                <span class={styles.moreInfo}></span>
            </div>
        </div>
    )
}

const PlanDownload: Component<{ plan: MrPlan, onDownload: (plan: MrPlan) => void }> = (props) => {
    const download = () => props.onDownload(props.plan)

    return (
        <PlanCard planId={props.plan.id}>
            <div class={downloadStyles.planDownload}>
                <span class={downloadStyles.planDownloadIcon} onClick={download}><DownloadCloudIcon /></span>
            </div>
        </PlanCard>
    )
}

export const PlanDownloads: Component<PlanDownloadsProps> = (props) => {
    return (
        <div class={classList(styles.cardContainer)}>
            <PlanDownloadLabel label={props.optionLabel}/>
            <For each={props.plans}>
                {(plan) => <PlanDownload plan={plan} onDownload={props.onDownload}/>}
            </For>
        </div>
    )
}
