import {Exclusion} from './Exclusion'

export const Other = () => {
    return (
        <Exclusion name={'Other'}>
            <p>Here are some of the things we don't cover. Full details can be found in your employees' plan documents.</p>
            <ul>
                <li>Benefits not included in your employees' plans</li>
                <li>Birth control, conception, sexual problems & gender re-assignment</li>
                <li>Cosmetic surgery</li>
                <li>Deafness</li>
                <li>Dialysis</li>
                <li>Learning disabilities, behavioural & developmental problems</li>
                <li>Pregnancy & childbirth</li>
                <li>Sleep problems & disorders</li>
            </ul>
        </Exclusion>
    )
}
