.KCaq5G_textBox {
  border: 1px solid var(--neutral-dark);
  background-color: #fff;
  border-radius: .5em;
  padding: .5em .75em;
  line-height: 1em;
}

.KCaq5G_label {
  width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7O0FBUUEiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9nbG9iYWwvZm9ybXMvVGV4dEJveC9UZXh0Qm94Lm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLnRleHRCb3gge1xuICAgIGJvcmRlcjogMXB4IHNvbGlkIHZhcigtLW5ldXRyYWwtZGFyayk7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogd2hpdGU7XG4gICAgYm9yZGVyLXJhZGl1czogMC41ZW07XG4gICAgcGFkZGluZzogMC41ZW0gMC43NWVtO1xuICAgIGxpbmUtaGVpZ2h0OiAxZW07XG59XG5cbi5sYWJlbCB7XG4gICAgd2lkdGg6IDEwMCU7XG59XG4iXSwibmFtZXMiOltdfQ== */