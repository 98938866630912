.hLn7oW_priceBreakdown {
  margin-top: 2em;
}

.hLn7oW_priceBreakdown tr > * {
  padding: .5em 1em;
}

.hLn7oW_priceBreakdown td:last-child {
  width: 40%;
}

.hLn7oW_priceBreakdown td:nth-child(2) {
  text-align: right;
}

.hLn7oW_priceBreakdown th:nth-child(2) {
  text-align: right;
  white-space: nowrap;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7QUFJQTs7OztBQUlBOzs7O0FBSUE7Ozs7QUFJQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvUXVvdGUvUXVvdGVGb290ZXIvUHJpY2VCcmVha2Rvd24ubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIucHJpY2VCcmVha2Rvd24ge1xuICAgIG1hcmdpbi10b3A6IDJlbVxufVxuXG4ucHJpY2VCcmVha2Rvd24gdHIgPiAqIHtcbiAgICBwYWRkaW5nOiAwLjVlbSAxZW1cbn1cblxuLnByaWNlQnJlYWtkb3duIHRkOmxhc3QtY2hpbGQge1xuICAgIHdpZHRoOiA0MCVcbn1cblxuLnByaWNlQnJlYWtkb3duIHRkOm50aC1jaGlsZCgyKSB7XG4gICAgdGV4dC1hbGlnbjogcmlnaHQ7XG59XG5cbi5wcmljZUJyZWFrZG93biB0aDpudGgtY2hpbGQoMikge1xuICAgIHRleHQtYWxpZ246IHJpZ2h0O1xuICAgIHdoaXRlLXNwYWNlOiBub3dyYXA7XG59XG4iXSwibmFtZXMiOltdfQ== */