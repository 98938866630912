import {BenefitType} from '@peachy/core-domain-pure'
import {Dictionary} from '@peachy/utility-kit-pure'


export type DefaultLimits = {
    [key: string]: number
}

export type DefaultLimit = number | DefaultLimits

export type BenefitFieldType = 'boolean' | 'select'

export type AvailableBenefit = {
    include?: boolean
    isEditable?: boolean
    defaultLimit?: DefaultLimit
}

export type Benefit = AvailableBenefit & {
    id: string,
    name: string,
    values?: number[] | string[]
    benefitTypes: BenefitType[]
    global?: boolean
    
    /**
     * @deprecated 
     * This type is for UI purposes only - benefit values and how they're renderered are now part of the display plan config.
     */ 
    type?: BenefitFieldType,

    /**
     *  @deprecated 
     * This type is for UI purposes only - more info is now part of the display plan config.
     */ 
    moreInfo?: string
}


export type PlanExcess = {
    id: string
    benefitTypes?: BenefitType[]
}

export type Plan = {
    id: string
    name: string
    availableBenefits: Dictionary<AvailableBenefit>
    excess?: PlanExcess
}

export type Excess = {
    defaultLimit: number
    values: number[]
}

export type Config = {
    plans: Plan[],
    benefits: Benefit[],
    excess: Dictionary<Excess>
}
