.Q9H59q_progressMessage {
  text-align: center;
  width: 100%;
}

.Q9H59q_progressMessageError {
  color: red;
}

.Q9H59q_buttonsContainer button {
  margin: .5em;
  font-weight: normal;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0E7Ozs7QUFJQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvTWFuYWdlTWVtYmVycy9idWxrLWFkZC9CdWxrQWRkTWVtYmVycy5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5wcm9ncmVzc01lc3NhZ2Uge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbn1cblxuLnByb2dyZXNzTWVzc2FnZUVycm9yIHtcbiAgICBjb2xvcjogcmVkO1xufVxuXG4uYnV0dG9uc0NvbnRhaW5lciBidXR0b24ge1xuICAgIGZvbnQtd2VpZ2h0OiBub3JtYWw7XG4gICAgbWFyZ2luOiAwLjVlbTtcbn1cbiJdLCJuYW1lcyI6W119 */