import {Button} from '../../../global/forms/Button'
import {FileReaderButton} from '../../../global/forms/FileReaderButton'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {firstMatchingAddressFetcher, parseExpectedFields} from '@peachy/client-kit'
import {getPrimaryLife} from '../../../providers/AccountSubscription/stores/AccountSubscriptionStore'
import {parseDmyDate} from '@peachy/utility-kit-pure'
import styles from './BulkAddMembers.module.css'
import {Accessor, createSignal} from 'solid-js'

const EXPECTED_FIELDS = [
    'firstname',
    'lastname',
    'dateOfBirth',
    'address',
    'email'
] as const

export function BulkAddMembers(props: { onComplete: () => void }) {
    const store = useStore()
    const [message, setMessage] = createSignal({} as ProgressMessage)
    const fetchFirstMatchingAddress = firstMatchingAddressFetcher()

    const parseTabular = async (text: string) => {
        const lives = await parseExpectedFields(EXPECTED_FIELDS, text)
        const startingIndex = store.getIndexForNewPolicy()

        for (let i = 0; i < lives.length; i++) {
            const life = lives[i]
            setMessage(m => ({ text: `Processing ${i + 1}/${lives.length}` }))
            const policy = store.getPolicies()[startingIndex + i] ?? store.addPolicy()
            const address = await fetchFirstMatchingAddress(life.address)
            store.updateLife(startingIndex + i, getPrimaryLife(policy).id, {
                ...life,
                dateOfBirth: parseDmyDate(life.dateOfBirth).getTime(),
                address: address,
                postcode: address?.postcode,
            })
        }
        props.onComplete()
    }

    const pasteFromClipboard = () => navigator.clipboard.readText().then(parseTabular)
        .catch(e => {
            console.error(e)
            setMessage(m => ({
                style: 'error',
                text: 'There was a problem with the data. Please check the header names are correct and that only the header and relevant employees were selected'
            }))
        })
    const onFileRead = (data: string) => parseTabular(data)
        .catch(e => {
            console.error(e)
            setMessage(m => ({
                style: 'error',
                text: 'There was a problem with the data. Please check the header names are correct and that all other columns are empty'
            }))
        })

    return (
        <section>
            <h3>Bulk upload</h3>
            <p>If you have members in a spreadsheet, you can add them all easily. The spreadsheet must have the correct
                column headings - just paste them into our <a download="bulk-template.csv"
                                                              href={`data:text/csv;charset=utf-8,${EXPECTED_FIELDS.join(',')}`}>template</a>
            </p>
            <p>To paste, copy the title row and all the members you want to add, then click Paste</p>
            <p>To upload a file, it must be saved as a csv</p>
            <p>NOTE: You can only add primary members, not additional family members</p>
            <ProgressMessage message={message}/>
            <div class={styles.buttonsContainer}>
                <Button theme="secondary" onClick={pasteFromClipboard}>Paste</Button>
                <FileReaderButton theme="secondary" onFileRead={onFileRead} accept=".csv">
                    Upload csv file
                </FileReaderButton>
            </div>
        </section>
    )
}

type ProgressMessage = {
    text?: string
    style?: 'error'
}

function ProgressMessage(props: { message: Accessor<ProgressMessage> }) {
    const styleClass = messageStyle => {
        switch (messageStyle) {
            case 'error':
                return styles.progressMessageError
        }
    }
    return (
        <div class={`${styles.progressMessage} ${styleClass(props.message().style)}`}>
            <p>{props.message().text}</p>
        </div>
    )
}
