.yl4Uaq_spinner {
  -webkit-animation: 1s linear infinite yl4Uaq_peachy-spinner__spin;
  animation: 1s linear infinite yl4Uaq_peachy-spinner__spin;
}

@-webkit-keyframes yl4Uaq_peachy-spinner__spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes yl4Uaq_peachy-spinner__spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0E7Ozs7Ozs7Ozs7QUFLQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2dsb2JhbC9pY29ucy9TcGlubmVyL1NwaW5uZXIubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuc3Bpbm5lciB7XG4gICAgLXdlYmtpdC1hbmltYXRpb246IHBlYWNoeS1zcGlubmVyX19zcGluIDFzIGxpbmVhciBpbmZpbml0ZTtcbiAgICBhbmltYXRpb246IHBlYWNoeS1zcGlubmVyX19zcGluIDFzIGxpbmVhciBpbmZpbml0ZTtcbn1cblxuQC13ZWJraXQta2V5ZnJhbWVzIHBlYWNoeS1zcGlubmVyX19zcGluIHtcbiAgICAwJSB7IC13ZWJraXQtdHJhbnNmb3JtOiByb3RhdGUoMGRlZyk7IH1cbiAgICAxMDAlIHsgLXdlYmtpdC10cmFuc2Zvcm06IHJvdGF0ZSgzNjBkZWcpOyB9XG59XG5cbkBrZXlmcmFtZXMgcGVhY2h5LXNwaW5uZXJfX3NwaW4ge1xuICAgIDAlIHsgdHJhbnNmb3JtOiByb3RhdGUoMGRlZyk7IH1cbiAgICAxMDAlIHsgdHJhbnNmb3JtOiByb3RhdGUoMzYwZGVnKTsgfVxufSJdLCJuYW1lcyI6W119 */