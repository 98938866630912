export * from './src/model/api/Legacy'
export * from './src/model/api/QuoteApi'
export * from './src/model/api/RequestMapper'

export * from './src/model/core/IndividualQuoteRequest'
export * from './src/model/core/IndividualQuoteResponse'
export * from './src/model/core/PolicyType'
export * from './src/model/core/ExcessDiscount'
export * from './src/model/core/QuoteBenefitType'
export * from './src/model/core/PricingBreakdown'
export * from './src/model/core/QuoteRegion'

export * from './src/util/Calc'
export * from './src/util/CsvParser'
export * from './src/util/MapUtils'

export * from './src/QuoteApiDefinition'

export * from './src/util/MapUtils'
export * from './src/util/CsvParser'
export * from './src/util/Calc'

        import * as me from '@peachy/quote-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src2938690931/src/codestar-connections.eu-west-2.amazonaws.com/git-http/879656270406/eu-west-2/5886de9c-5c2f-4d33-b265-d0ba742d8141/peachy-punnet/peachy-mono-repo/comp-kit/quote/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/quote-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    