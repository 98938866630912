.eaAIcq_addressList {
  border: 2px solid var(--peachy-pink);
  background-color: var(--form-input-bg-color);
  border-radius: .5em;
  outline: none;
  min-width: 25em;
  height: 7em;
  transform: translateY(2.1em);
}

.eaAIcq_addressItem {
  padding: .2em 1em;
  display: block;
}

.eaAIcq_addressItem:hover {
  cursor: pointer;
  background-color: var(--form-hover);
}

.eaAIcq_selectedAddress, .eaAIcq_selectedAddress:hover {
  background-color: var(--form-selected);
  color: var(--form-input-bg-color);
}

.eaAIcq_invalidField input[type="text"] {
  border-color: var(--form-error);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7QUFVQTs7Ozs7QUFLQTs7Ozs7QUFLQTs7Ozs7QUFLQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvQWRkcmVzc0xvb2t1cC9BZGRyZXNzTG9va3VwLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLmFkZHJlc3NMaXN0IHtcbiAgICBib3JkZXI6IDJweCBzb2xpZCB2YXIoLS1wZWFjaHktcGluayk7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tZm9ybS1pbnB1dC1iZy1jb2xvcik7XG4gICAgYm9yZGVyLXJhZGl1czogMC41ZW07XG4gICAgb3V0bGluZTogbm9uZTtcbiAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVkoMi4xZW0pO1xuICAgIGhlaWdodDogN2VtO1xuICAgIG1pbi13aWR0aDogMjVlbTtcbn1cblxuLmFkZHJlc3NJdGVtIHtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICBwYWRkaW5nOiAwLjJlbSAxZW07XG59XG5cbi5hZGRyZXNzSXRlbTpob3ZlciB7XG4gICAgY3Vyc29yOiBwb2ludGVyO1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWZvcm0taG92ZXIpO1xufVxuXG4uc2VsZWN0ZWRBZGRyZXNzLCAuc2VsZWN0ZWRBZGRyZXNzOmhvdmVyIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1mb3JtLXNlbGVjdGVkKTtcbiAgICBjb2xvcjogdmFyKC0tZm9ybS1pbnB1dC1iZy1jb2xvcik7XG59XG5cbi5pbnZhbGlkRmllbGQgaW5wdXRbdHlwZT10ZXh0XSB7XG4gICAgYm9yZGVyLWNvbG9yOiB2YXIoLS1mb3JtLWVycm9yKTtcbn1cbiJdLCJuYW1lcyI6W119 */