.kS0ffq_container {
  flex-direction: column;
  row-gap: 3em;
  margin-top: 2em;
  display: flex;
}

.kS0ffq_rowStructure {
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1em;
  display: flex;
}

.kS0ffq_rowStructure > div:last-of-type > h4 {
  text-align: end;
}

.kS0ffq_details {
  flex-direction: column;
  row-gap: 1em;
  display: flex;
}

.kS0ffq_subTitle {
  font-size: var(--font-size-root-xsmall);
}

.kS0ffq_disabled {
  opacity: .5;
  pointer-events: none;
  user-select: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7QUFPQTs7Ozs7OztBQU9BOzs7O0FBSUE7Ozs7OztBQU1BOzs7O0FBSUEiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9wYWdlcy9kYXNoYm9hcmQvQnJva2VyUXVvdGUvQnJva2VyUXVvdGUubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuY29udGFpbmVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgcm93LWdhcDogM2VtO1xuICAgIG1hcmdpbi10b3A6IDJlbTtcbn1cblxuLnJvd1N0cnVjdHVyZSB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgICBjb2x1bW4tZ2FwOiAxZW07XG59XG5cbi5yb3dTdHJ1Y3R1cmUgPiBkaXY6bGFzdC1vZi10eXBlID4gaDQge1xuICAgIHRleHQtYWxpZ246IGVuZDtcbn1cblxuLmRldGFpbHMge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICByb3ctZ2FwOiAxZW07XG59XG5cbi5zdWJUaXRsZSB7XG4gICAgZm9udC1zaXplOiB2YXIoLS1mb250LXNpemUtcm9vdC14c21hbGwpO1xufVxuXG4uZGlzYWJsZWQge1xuICAgIG9wYWNpdHk6IDAuNTtcbiAgICBwb2ludGVyLWV2ZW50czogbm9uZTtcbiAgICB1c2VyLXNlbGVjdDogbm9uZTtcbn1cbiJdLCJuYW1lcyI6W119 */