import {createToggleSignal} from '@peachy/client-kit'
import {startOfToday} from 'date-fns'
import {Component, createComputed} from 'solid-js'
import {useStore} from '../../providers/AccountSubscription/AccountSubscriptionProvider'
import {MoreInfoModal} from '../Modal/MoreInfoModal'

export const StartDateNotice: Component = () => {
    const store = useStore()
    const [showModal, toggleModal] = createToggleSignal(false)

    createComputed(() => {
        if (store.hasSubscriptionStartDateExpired()) {
            store.updateStartDate(startOfToday().getTime())
            toggleModal()
        }
    })

    return (
        <MoreInfoModal isOpen={showModal()} onDismiss={toggleModal}>
            <h3>Start Date Change</h3>
            <section>
                <p>As you started this quote previously, we have updated your plan start date to today.</p>
                <p>This may have changed the price, please check the price still meets your needs</p>
            </section>
        </MoreInfoModal>
    )
}
