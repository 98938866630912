import {Address, isValidProperty, PaymentDetails} from '@peachy/core-domain-pure'
import {Component, createSignal, Show} from 'solid-js'
import {AddressLookup} from '../../../../components/AddressLookup/AddressLookup'
import {useStore} from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import styles from './BillingDetails.module.css'
import {Draft} from '@peachy/utility-kit-pure'
import {Toggle} from '@peachy/client-kit'


export const BillingDetails: Component<{ ref: any }> = (props) => {
    const store = useStore()
    const paymentDetails = store.getPaymentDetails()

    const [hasSeparateBilling, setHasSeparateBilling] = createSignal<boolean>(paymentDetails?.hasSeparateBillingAddress)
    const [selectedAddress, setSelectedAddress] = createSignal<Draft<Address>>(paymentDetails?.billingAddress)

    const useSeparateBilling = (toggleValue: boolean) => {
        setHasSeparateBilling(toggleValue)

        //clear address if we're disabling the toggle
        if (!toggleValue) {
            setSelectedAddress()
        }

        store.updateBillingAddress(hasSeparateBilling(), selectedAddress())
    }

    const isValid = () => {
        const paymentDetails = { hasSeparateBillingAddress: hasSeparateBilling(), billingAddress: selectedAddress() } as PaymentDetails
        return isValidProperty(paymentDetails, 'billingAddress', null, PaymentDetails)
    }

    props.ref({ isValid })

    return (
        <>
            <fieldset class="toggle-options">
                <label>
                    <span>Different billing address?</span>
                    <Toggle isOn={hasSeparateBilling()} onToggle={useSeparateBilling} class={styles.toggle}/>
                </label>
            </fieldset>

            <Show when={hasSeparateBilling()}>
                <fieldset class={styles.AddressLookup}>
                    <AddressLookup
                        initialSearchTerm={paymentDetails?.billingAddress?.display ?? ''}
                        onFieldChange={(value: string) => {
                            //if user has cleared field, clear selected address
                            if (value.trim().length === 0) {
                                setSelectedAddress()
                            }
                        }}
                        onSelectAddress={(address: Address) => {
                            setSelectedAddress(address)
                            store.updateBillingAddress(hasSeparateBilling(), selectedAddress())
                        }}
                        isValid={isValid}
                        resetStateOnClearInput
                    />
                </fieldset>
            </Show>
        </>

    )
}
