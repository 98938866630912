.E7Lizq_container {
  flex-direction: column;
  row-gap: 1em;
  display: flex;
}

.E7Lizq_container > p:first-child.E7Lizq_input {
  margin-top: 2.4em;
}

.E7Lizq_input {
  border: 2px solid var(--pencil-color);
  cursor: pointer;
  background-color: #fff;
  border-radius: .5em;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: .5em;
  display: flex;
}

.E7Lizq_input, .E7Lizq_list {
  min-width: 260px;
  font-size: 1rem;
}

.E7Lizq_listItem {
  padding: .5em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7OztBQU1BOzs7O0FBSUE7Ozs7Ozs7Ozs7OztBQVlBOzs7OztBQU1BIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvcGFnZXMvZGFzaGJvYXJkL0Jyb2tlclF1b3RlL1Byb2R1Y3REZXRhaWxzL0l0ZW1Ecm9wZG93bi9JdGVtRHJvcGRvd24ubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuY29udGFpbmVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgcm93LWdhcDogMWVtO1xufVxuXG4uY29udGFpbmVyID4gcDpmaXJzdC1jaGlsZC5pbnB1dHtcbiAgICBtYXJnaW4tdG9wOiAyLjRlbTtcbn1cblxuLmlucHV0IHtcbiAgICBib3JkZXI6IDJweCBzb2xpZCB2YXIoLS1wZW5jaWwtY29sb3IpO1xuICAgIHBhZGRpbmc6IDAuNWVtO1xuICAgIGJvcmRlci1yYWRpdXM6IDAuNWVtO1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG5cbi5pbnB1dCxcbi5saXN0IHtcbiAgICBtaW4td2lkdGg6IDI2MHB4O1xuICAgIGZvbnQtc2l6ZTogMXJlbTtcbn1cblxuLmxpc3RJdGVtIHtcbiAgICBwYWRkaW5nOiAwLjVlbTtcbn0iXSwibmFtZXMiOltdfQ== */