import {User} from '@peachy/core-domain-pure'

import {createSignal, Show} from 'solid-js'
import {TextBox} from '../../../global/forms/TextBox/TextBox'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {validateUser} from '../../../service/validation/ValidationService'
import {CaptureForm} from '../CaptureForm'
import {useIamService} from '../../../providers/AppServiceProvider'
import {A} from '@solidjs/router'

export function CaptureAdminName() {
    const store = useStore()
    const iamService = useIamService()

    const [firstName, setFirstName] = createSignal(store.getUser().firstname ?? '')
    const [lastName, setLastName] = createSignal(store.getUser()?.lastname ?? '')

    const trimFirstName = () => firstName().trim()
    const trimLastName = () => lastName().trim()

    const onSubmit = async (event) => {
        event.preventDefault()
        store.updateUserName(trimFirstName(), trimLastName())
    }

    return (
        <CaptureForm
            onSubmit={onSubmit}
            errorFields={['firstname', 'lastname']}
            validateFields={() => validateUser({
                firstname: trimFirstName(),
                lastname: trimLastName()
            } as User)}
            autocomplete="off"
        >
            <h4>We'll get you an awesome price in seconds. Let's go!</h4>

            <fieldset>
                <TextBox name="firstname" placeholder="First name" value={trimFirstName()} onInput={(e) => setFirstName(e.currentTarget.value)} autoFocus/>
                <TextBox name="lastname" placeholder="Last name" value={trimLastName()} onInput={(e) => setLastName(e.currentTarget.value)}/>
            </fieldset>

            <Show when={!iamService.isSignedIn()}>
                <nav>Already have an account? <A href="/login">Log in here &rarr;</A></nav>
            </Show>

        </CaptureForm>
    )
}
