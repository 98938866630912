import {Life, MrPlan, MrPolicy} from '@peachy/core-domain-pure'
import {toTitleCase} from '@peachy/utility-kit-pure'
import {Component} from 'solid-js'
import {usePlanConfig} from '../../config/configureApp'
import {getPolicies} from '../../providers/AccountProvider'
import {useStore} from '../../providers/AccountSubscription/AccountSubscriptionProvider'
import {useDocGenService} from '../../providers/AppServiceProvider'
import {PlanEmployeeAssignment} from './PlanAssignment/PlanEmployeeAssignment'
import {PlanConfiguration} from './PlanConfiguration/PlanConfiguration'
import {PlanDownloads} from './PlanDownload/PlanDownloads'
import {createMemberDocTicket} from '../../service/documents/createSamplePolicyData'
import styles from './PlanBuilder.module.css'

export const PlanBuilder: Component = () => {
    const store = useStore(), planConfig = usePlanConfig(), docGenService = useDocGenService()

    const isPlanEditable = (planId: string): boolean => {
        const isEditable = store.isPlanEditable()

        if (store.getViewMode() === 'PORTAL' && isEditable) {
            return !getPolicies().some((p: MrPolicy) => p.getPrimaryLife().planId === planId)
        }

        return isEditable
    }

    const onDownload = async (plan: MrPlan) => {
        const filename = (plan: MrPlan) => {
             const planName = toTitleCase(plan.name).replaceAll(' ','')
            return `PeachyMemberPlan-${planName}-SampleLife.pdf`
        }

        const ticket = createMemberDocTicket(plan, store.getSubscriptionStartDate())
        const link = await docGenService.getPdfLink(ticket, filename(plan))
        window.open(link, `_${plan.name}MemberSamplePlanPdf`)
    }

    return (
        <div class={styles.planBuilder}>
            <PlanConfiguration
                planConfig={planConfig}
                plans={store.getPlans()}
                lives={store.getLives() as Life[]}
                onUpdateBenefitLimit={(planId: string, benefitId: string, value: number) => store.updateBenefitLimit(planId, benefitId, value)}
                onUpdateExcess={(planId: string, value: number) => store.updatePlanExcess(planId, value)}
                isPlanEditable={isPlanEditable}
                onAddBenefit={(planId: string, benefitId: string) => store.addBenefit(planId, benefitId)}
                onRemoveBenefit={(planId: string, benefitId: string) => store.removeBenefit(planId, benefitId)}
            />

            <PlanDownloads
                plans={store.getPlans()}
                optionLabel="DOWNLOAD SAMPLE MEMBER GUIDE"
                onDownload={onDownload} />

            <PlanEmployeeAssignment />
        </div>
    )
}
