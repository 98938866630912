.Ur944q_DragPane {
  width: 100vw;
  height: 100vh;
}

.Ur944q_DragPane[data-drag-state="draggable"] {
  cursor: grab;
}

.Ur944q_DragPane[data-drag-state="dragging"] {
  cursor: grabbing;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBTUE7Ozs7QUFJQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vY2xpZW50LWtpdC9zcmMvY29udHJvbGxlcnMvRHJhZ1Njb3BlL0RyYWdTY29wZS5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5EcmFnUGFuZSB7XG4gICAgaGVpZ2h0OiAxMDB2aDtcbiAgICB3aWR0aDogMTAwdnc7XG59XG5cblxuLkRyYWdQYW5lW2RhdGEtZHJhZy1zdGF0ZT0nZHJhZ2dhYmxlJ10ge1xuICAgIGN1cnNvcjogZ3JhYjtcbn1cblxuLkRyYWdQYW5lW2RhdGEtZHJhZy1zdGF0ZT0nZHJhZ2dpbmcnXSB7XG4gICAgY3Vyc29yOiBncmFiYmluZztcbn1cblxuOmdsb2JhbCguc3BhY2VQcmVzc2VkKSAqW2RhdGEtZ3JhYmJlZD10cnVlXSB7XG5cbn1cblxuIl0sIm5hbWVzIjpbXX0= */