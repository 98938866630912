._53mktW_quoteFooter {
  background-color: var(--primary-color-1);
  color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 90px;
  max-height: 90px;
  padding: 0 8em;
  display: flex;
  position: sticky;
  bottom: 0;
}

@media screen and (width <= 1400px) {
  ._53mktW_quoteFooter {
    font-size: var(--font-size-small);
  }
}

@media screen and (width <= 800px) {
  ._53mktW_quoteFooter {
    padding-left: 2em;
    padding-right: 6em;
  }

  ._53mktW_quoteFooter button {
    padding-left: 2em;
    padding-right: 2em;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7Ozs7O0FBY0E7RUFDSTs7Ozs7QUFLSjtFQUNJOzs7OztFQUtBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvcGFnZXMvZGFzaGJvYXJkL0Jyb2tlclF1b3RlL1F1b3RlRm9vdGVyL1F1b3RlRm9vdGVyLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLnF1b3RlRm9vdGVyIHtcbiAgICBwb3NpdGlvbjogc3RpY2t5O1xuICAgIGJvdHRvbTogMDtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1wcmltYXJ5LWNvbG9yLTEpO1xuICAgIGNvbG9yOiB3aGl0ZTtcbiAgICBwYWRkaW5nOiAwIDhlbTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIHdpZHRoOiAxMDB2dztcbiAgICBoZWlnaHQ6IDkwcHg7XG4gICAgbWF4LWhlaWdodDogOTBweDtcbn1cblxuQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogMTQwMHB4KSB7XG4gICAgLnF1b3RlRm9vdGVyIHtcbiAgICAgICAgZm9udC1zaXplOiB2YXIoLS1mb250LXNpemUtc21hbGwpO1xuICAgIH1cbn1cblxuQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogODAwcHgpIHtcbiAgICAucXVvdGVGb290ZXIge1xuICAgICAgICBwYWRkaW5nLWxlZnQ6IDJlbTtcbiAgICAgICAgcGFkZGluZy1yaWdodDogNmVtO1xuICAgIH1cblxuICAgIC5xdW90ZUZvb3RlciBidXR0b24ge1xuICAgICAgICBwYWRkaW5nLWxlZnQ6IDJlbTtcbiAgICAgICAgcGFkZGluZy1yaWdodDogMmVtO1xuICAgIH1cbn0iXSwibmFtZXMiOltdfQ== */