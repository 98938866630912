.U0T99G_planSelector {
  border: 1px solid var(--pencil-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: .5em;
  align-items: center;
  padding: .5em 1em .5em .5em;
  display: flex;
  overflow: hidden;
}

.U0T99G_planSelector > i {
  pointer-events: none;
  position: absolute;
  right: 15px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7Ozs7QUFhQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvTWFuYWdlTWVtYmVycy90YWJsZS9jZWxsL1BsYW5TZWxlY3Rvci5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5wbGFuU2VsZWN0b3Ige1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBib3JkZXI6IDFweCBzb2xpZCB2YXIoLS1wZW5jaWwtY29sb3IpO1xuICAgIHBhZGRpbmc6IDAuNWVtIDFlbSAwLjVlbSAwLjVlbTtcbiAgICBib3JkZXItcmFkaXVzOiAwLjVlbTtcbiAgICB3aGl0ZS1zcGFjZTogbm93cmFwO1xuICAgIG92ZXJmbG93OiBoaWRkZW47XG4gICAgdGV4dC1vdmVyZmxvdzogZWxsaXBzaXM7XG4gICAgY3Vyc29yOiBwb2ludGVyO1xuICAgIGJveC1zaXppbmc6IGJvcmRlci1ib3g7IC8qIFN0b3AgZWxsaXBzaXMgZnJvbSBvdmVybGFwcGluZyB3aXRoIGljb24qL1xufVxuXG4ucGxhblNlbGVjdG9yID4gaSB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHJpZ2h0OiAxNXB4O1xuICAgIHBvaW50ZXItZXZlbnRzOiBub25lO1xufSJdLCJuYW1lcyI6W119 */