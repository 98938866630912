import {MrPolicy} from '@peachy/core-domain-pure'
import {Draft} from '@peachy/utility-kit-pure'
import {Component, createMemo, Show} from 'solid-js'
import Partner from '../../../../global/icons/Partner'
import {useStore} from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {getSecondaryLife} from '../../../../providers/AccountSubscription/stores/AccountSubscriptionStore'
import tableStyles from '../ManageMembersTable.module.css'
import {BirthDateCell} from '../cell/BirthDateCell'
import {EmailCell} from '../cell/EmailCell'
import {FirstNameCell} from '../cell/FirstNameCell'
import {LastNameCell} from '../cell/LastNameCell'
import {RemoveDependant} from '../cell/RemoveDependant'


export const SecondLifeRow: Component<{ policy: Draft<MrPolicy>, policyIndex: number }> = (props) => {
    const store = useStore()

    const secondaryCellProps = createMemo(() => {
        const life = getSecondaryLife(props.policy)

        return life ? {
            life: life,
            policyId: props.policy.id,
            policyIndex: props.policyIndex,
            readonly: !store.canEditLife(life.id)
        } : undefined
    })

    return (
        <Show when={secondaryCellProps()}>
            <tr>
                <td><i class="fa-solid fa-arrow-turn-down-right" /></td>
                <FirstNameCell {...secondaryCellProps()} placeholder="Partner"/>
                <LastNameCell {...secondaryCellProps()} />
                <BirthDateCell {...secondaryCellProps()} policyStartDate={props.policy.startDate} />
                <td/>{/* address field not needed  */}
                <EmailCell {...secondaryCellProps()}/>
                <td/>{/* plan field not needed  */}
                <td class={tableStyles.dependants}>
                    <span><Partner /></span>
                </td>
                <RemoveDependant {...secondaryCellProps()} />
            </tr>
        </Show>
    )
}
