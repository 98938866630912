.DTEvqa_myQuotesHeader {
  justify-content: space-between;
  display: flex;
}

.DTEvqa_goToQuote {
  color: #000;
  text-decoration: underline;
}

.DTEvqa_addQuote {
  flex-direction: row;
  column-gap: 20px;
  display: flex;
}

.DTEvqa_addQuote > button {
  width: fit-content;
  font-size: var(--font-size-normal);
  padding: 1rem 2rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0E7Ozs7O0FBS0E7Ozs7OztBQU1BIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvcGFnZXMvZGFzaGJvYXJkL015UXVvdGVzL015UXVvdGVzLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLm15UXVvdGVzSGVhZGVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2Vlbjtcbn1cblxuLmdvVG9RdW90ZSB7XG4gICAgY29sb3I6IGJsYWNrO1xuICAgIHRleHQtZGVjb3JhdGlvbjogdW5kZXJsaW5lO1xufVxuXG4uYWRkUXVvdGUge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgICBjb2x1bW4tZ2FwOiAyMHB4O1xufVxuXG4uYWRkUXVvdGUgPiBidXR0b24ge1xuICAgIHdpZHRoOiBmaXQtY29udGVudDtcbiAgICBwYWRkaW5nOiAxcmVtIDJyZW07XG4gICAgZm9udC1zaXplOiB2YXIoLS1mb250LXNpemUtbm9ybWFsKTtcbn0iXSwibmFtZXMiOltdfQ== */