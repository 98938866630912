.-Xv1Wa_DatePickerHeader {
  flex-direction: column;
  display: flex;
}

.-Xv1Wa_nudgeSelector {
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.-Xv1Wa_nudgeLeft:before {
  content: "<";
}

.-Xv1Wa_nudgeSelectorText {
  cursor: pointer;
}

.-Xv1Wa_nudgeRight:before {
  content: ">";
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQ0E7Ozs7O0FBS0E7Ozs7Ozs7O0FBU0E7Ozs7QUFJQTs7OztBQUtBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9jbGllbnQta2l0L3NyYy9jb21wb25lbnRzL0RhdGVQaWNrZXIvRGF0ZVBpY2tlckhlYWRlci9EYXRlUGlja2VySGVhZGVyLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiXG4uRGF0ZVBpY2tlckhlYWRlciB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xufVxuXG4ubnVkZ2VTZWxlY3RvciB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbn1cblxuXG4ubnVkZ2VMZWZ0OjpiZWZvcmUge1xuICAgIGNvbnRlbnQ6ICc8Jztcbn1cblxuLm51ZGdlU2VsZWN0b3JUZXh0IHtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cblxuLm51ZGdlUmlnaHQ6OmJlZm9yZSB7XG4gICAgY29udGVudDogJz4nO1xufVxuIl0sIm5hbWVzIjpbXX0= */