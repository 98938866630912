
export type PricingBreakdown = {
    a_baseRiskCost: BenefitPriceBreakdown
    b_adjustedForCorrelation: BenefitPriceBreakdown
    c_adjustedForExcessDiscounts: BenefitPriceBreakdown
    d_adjustedForFinalDiscounts: BenefitPriceBreakdown
    e_adjustedForContingency: BenefitPriceBreakdown
    f_adjustedForLossRatio: BenefitPriceBreakdown
    g_adjustedForIpt: BenefitPriceBreakdown
    h_adjustedForPriceResolution: BenefitPriceBreakdown
}

export type BenefitPriceBreakdown = {
    [_k: string]: number
}
