import {
    BenefitType,
    CompanyPolicyDocData,
    DocGenTicket,
    mapToLegacyPlan,
    mapToLegacyPolicy,
    MemberPolicyDocData,
    MrLife,
    MrLifeBenefit,
    MrPlan,
    MrPolicy,
    toClass
} from '@peachy/core-domain-pure'
import {KeyMapped, mapById, newUUID, values} from '@peachy/utility-kit-pure'

import {ROOT_DOMAIN} from '../../App'

const createLife = (plan: MrPlan, startDate: number) => toClass({
    id: newUUID(),
    planId: plan.id,
    benefits: createLifeBenefits(plan),
    dateOfBirth: (new Date('2000/06/03')).getTime(),
    firstname: 'Your',
    lastname: 'name',
    address: {
        display: 'Your address'
    },
    postcode: 'ABC 123',
    totalMonthlyPremium: 1234,
    type: 'PRIMARY',
    email: `your@email.com`,
    startDate
}, MrLife)

const createLifeBenefits = (plan: MrPlan): KeyMapped<MrLifeBenefit, BenefitType> => {
    return mapById(values(plan.benefits).map(pb => {
            return {
                id: pb.id,
                status: 'ACTIVE',
                limit: pb.limit,
            }
        })
    )
}

export const createPolicy = (plan: MrPlan, startDate: number) => {
    const life = createLife(plan, startDate)

    return toClass({
        id: 'TEMP123',
        status: 'ACTIVE',
        startDate: startDate,
        totalMonthlyPremium: life.totalMonthlyPremium,
        lives: {
            [life.id]: life
        }
    }, MrPolicy)
}

export const createMemberDocTicket = (plan: MrPlan, startDate: number): DocGenTicket<MemberPolicyDocData> => ({
    templateName: 'MemberPolicyDoc',
    docData: toClass({
        policy: mapToLegacyPolicy(createPolicy(plan, startDate)),
        plans: mapById([mapToLegacyPlan(plan)]),
        domain: ROOT_DOMAIN,
        isSample: true,
        subscriptionStartDate: startDate,
        company: {
            name: 'Your Company'
        }
    }, MemberPolicyDocData)
})

export const createEmployerDocTicket = (plans: MrPlan[], startDate: number): DocGenTicket<CompanyPolicyDocData> => ({
        templateName: 'CompanyPolicyDoc',
        docData: new CompanyPolicyDocData({
            domain: ROOT_DOMAIN,
            isSample: true,
            subscriptionStartDate: startDate,
            planNumber: 'Your Reference Number',
            plans: plans.map(mapToLegacyPlan),
            policies: plans.map(plan => mapToLegacyPolicy(createPolicy(plan, startDate))),
            company: {
                id: 'YOUR_COMPANY_ID',
                name: 'Your Company',
                address: {
                    display: 'Your Company address',
                },
            },
            email: 'yourcompany@email.com',
            versionDate: new Date().getTime(),
            totalMonthlyPremium: 4936
        } as CompanyPolicyDocData)
    }
)
