.IwrWma_CompanyTypeahead {
  flex-direction: column;
  min-width: -webkit-fill-available;
  display: flex;
}

.IwrWma_SuggestionsList {
  border: 2px solid var(--peachy-pink);
  background-color: var(--form-input-bg-color);
  border-radius: .5em;
  min-height: 4em;
  max-height: 14em;
  transform: translateY(.2em);
}

.IwrWma_SuggestionsItem {
  padding: .2em 1em;
  display: block;
}

.IwrWma_SuggestionsItem:hover {
  cursor: pointer;
  background-color: var(--form-hover);
}

.IwrWma_selectedSuggestions, .IwrWma_selectedSuggestions:hover {
  background-color: var(--form-selected);
  color: var(--form-input-bg-color);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7OztBQU1BOzs7Ozs7Ozs7QUFTQTs7Ozs7QUFLQTs7Ozs7QUFLQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvQ29tcGFueS9Db21wYW55VHlwZWFoZWFkLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLkNvbXBhbnlUeXBlYWhlYWQge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBtaW4td2lkdGg6IC13ZWJraXQtZmlsbC1hdmFpbGFibGU7XG59XG5cbi5TdWdnZXN0aW9uc0xpc3Qge1xuICAgIGJvcmRlcjogMnB4IHNvbGlkIHZhcigtLXBlYWNoeS1waW5rKTtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1mb3JtLWlucHV0LWJnLWNvbG9yKTtcbiAgICBib3JkZXItcmFkaXVzOiAwLjVlbTtcbiAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVkoMC4yZW0pO1xuICAgIG1pbi1oZWlnaHQ6IDRlbTtcbiAgICBtYXgtaGVpZ2h0OiAxNGVtO1xufVxuXG4uU3VnZ2VzdGlvbnNJdGVtIHtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICBwYWRkaW5nOiAwLjJlbSAxZW07XG59XG5cbi5TdWdnZXN0aW9uc0l0ZW06aG92ZXIge1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1mb3JtLWhvdmVyKTtcbn1cblxuLnNlbGVjdGVkU3VnZ2VzdGlvbnMsIC5zZWxlY3RlZFN1Z2dlc3Rpb25zOmhvdmVyIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1mb3JtLXNlbGVjdGVkKTtcbiAgICBjb2xvcjogdmFyKC0tZm9ybS1pbnB1dC1iZy1jb2xvcik7XG59XG4iXSwibmFtZXMiOltdfQ== */